import React from "react";
import styled from "styled-components";
// import sunIcon from "../../asset/svgs/sun-icon.svg";
// import moonIcon from "../../asset/svgs/moon-icon.svg";
import { MODE } from "../../store/mode";
import { useMode, useSetMode } from "../../store/mode/hooks";
import TestIcon from "../../asset/header-icons/sun-test-icon.png";
// import sleepIcon from "../../asset/header-icons/sleep-mode.png";
import nightIcon from "../../asset/header-icons/night.png";

// const CustomLabel = styled.label<{ mode: MODE }>`
//   position: relative;
//   display: flex;
//   width: 56px;
//   height: 28px;
//   padding: 0px 2px 1px 2px;
//   align-items: center;
//   border-radius: 131px;
//   background-color: ${(props) =>
//     props.mode === MODE.DARK ? "#616161" : "#e0e0e0"};
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
//   cursor: pointer;
// `;

// const CustomSpan = styled.span<{ mode: MODE }>`
//   display: flex;
//   width: 24px;
//   height: 24px;
//   align-items: center;
//   justify-content: center;
//   gap: 10px;
//   margin-top: 2px;
//   flex-shrink: 0;
//   border-radius: 2000px;
//   background: #212121;
//   transform: translateX(
//     ${(props) => (props.mode === MODE.DARK ? "1px" : "28px")}
//   );
//   transition: 0.3s ease-in-out;
// `;
const Image = styled.img`
  width: 17px;
  cursor: pointer;
`;
const SecondImage = styled.img`
  width: 20px;
  cursor: pointer;
`;
const DarkModeToggle = () => {
  const mode = useMode();
  const setMode = useSetMode();
  return (
    <>
      {/* <Image  src={mode===MODE.DARK? sleepIcon : TestIcon} alt="" /> */}
      <div onClick={() => setMode(mode === MODE.DARK ? MODE.LIGHT : MODE.DARK)}>
        {mode === MODE.DARK ? (
          <SecondImage src={TestIcon} alt="" />
        ) : (
          <Image src={nightIcon} alt="" />
        )}
      </div>
      {/* <CustomLabel
        mode={mode}
        onClick={() => setMode(mode === MODE.DARK ? MODE.LIGHT : MODE.DARK)}
        className="switch"
      > */}

      {/* <CustomSpan mode={mode} className="slider">
          {mode === MODE.DARK ? (
            <img src={moonIcon} alt="" />
          ) : (
            <img src={sunIcon} alt="" />
          )}
        </CustomSpan> */}
      {/* </CustomLabel> */}
    </>
  );
};

export default DarkModeToggle;
