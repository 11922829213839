import React from "react";
import styled from "styled-components";
import { bgColor } from "../theme/colors";
import Navbar from "../component/layouts/Navbar";
import StoryCard from "../component/StoryCard";
import MissionCard from "../component/MissionCard";
import ContactUsCard from "../component/ContactUsCard";
import FooterCard from "../component/layouts/Footer";
import CommunityCard from "../component/CommunityCard";
import { MODE } from "../store/mode";
import { useMode } from "../store/mode/hooks";
import { BREAKPOINTS } from "../theme/breakpoints";

const Header = styled.header`
  width: 100%;
  /* height: 130px; */
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  z-index: 999;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px 0;
  }
`;
const MainDiv = styled.div<{ mode: MODE }>`
  width: 100vw;
  height: auto;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: absolute;
  top: 0;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 285px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 227px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 170px;
  }
`;
// const AboutUsImage = styled.img`
//   width: 100%;
//   height: 285px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   @media (max-width: ${BREAKPOINTS.md}px){
//   height: 227px;
// }
// `;
const OverContentBox = styled.div<{ mode: MODE }>`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0px 0px 8px 8px;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background: var(--Primary-main, #03c988);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding-top: 80px;
  /* text */
  color: ${(props) =>
    props.mode === MODE.DARK ? "rgba(0, 0, 0, 0.87)" : "#FAFAFA"};
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 72px */
  letter-spacing: -0.5px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 55px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 48px;
    padding-top: 60px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 40px;
    padding-top: 40px;
  }
`;
const StoryBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 35px;
  }
`;
const MissionBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const ContactUsBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const CommunityBox = styled.div<{ mode: MODE }>`
  border-radius: 0px 0px 40px 40px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const BelowFooterDiv = styled.div`
  background: #424242;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  top: -40px;
  background: #424242;
  display: flex;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
  }
  @media (max-width: 600px) {
    height: 600px;
  }
`;

const AboutUs = () => {
  const mode = useMode();

  return (
    <MainDiv mode={mode}>
      <Header>
        <Navbar />
      </Header>
      <ImageDiv>
        {/* <AboutUsImage src={aboutUsImage} alt="" /> */}
        <OverContentBox mode={mode}>
          <p>About Us</p>
        </OverContentBox>
      </ImageDiv>
      <StoryBox mode={mode}>
        <StoryCard />
      </StoryBox>
      <MissionBox mode={mode}>
        <MissionCard />
      </MissionBox>
      <ContactUsBox mode={mode}>
        <ContactUsCard />
      </ContactUsBox>
      <CommunityBox mode={mode}>
        <CommunityCard />
      </CommunityBox>

      <BelowFooterDiv>
        <FooterDiv>
          <FooterCard />
        </FooterDiv>
      </BelowFooterDiv>
    </MainDiv>
  );
};

export default AboutUs;
