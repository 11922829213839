import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextColor, {
  BoldParagraph3,
  Heading3,
  Heading6,
} from "../../theme/typography";
import rightArrow from "../../asset/svgs/right-small-arrow.svg";
import rightWhiteArrow from "../../asset/svgs/right-small-white-arrow.svg";
import rightArrowHovered from "../../asset/svgs/right-small-arrow-v2.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../../theme/breakpoints";
import { blogs } from "../../constant/blogPosts";
import moment from "moment";

const MainDiv = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const TextDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeadingDiv = styled.div`
  width: 65%;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 70%;
  }
`;
const SubHeadingDiv = styled.div`
  text-align: right;
  width: 35%;
`;
const ImagesDiv = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;
const LeftImageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 45%;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    gap: 12px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 5px;
    width: 50%;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const RightImageBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  gap: 30px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 10px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 50%;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;

const TagBox = styled.div`
  display: flex;
  gap: 13px;
  @media (max-width: ${BREAKPOINTS.xxxl}px) {
    flex-wrap: wrap;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 9px;
  }
`;
const Tag = styled.div<{ mode: MODE }>`
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? "#757575" : "#e0e0e0"};
  /* Text */
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.secondary : TextColor.primary};
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
  }
`;

const RightImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    gap: 10px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 5px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 12px;
  }
`;
const Image = styled.img<{length:number}>`
  width: 100%;
  /* height: 220px; */
  height: ${(props)=>props.length===2?'580px':"220px"};
  border-radius: 8px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    /* height: 190px; */
    height: ${(props)=>props.length===2?'550px':"190px"};
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    /* height: 170px; */
    height: ${(props)=>props.length===2?'530px':"170px"};

  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    /* height: 133px; */
    height: ${(props)=>props.length===2?'430px':"133px"};

  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    /* height: 100px; */
    height: ${(props)=>props.length===2?'360px':"100px"};

  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    /* height: 150px; */
    height: ${(props)=>props.length===2?'550px':"190px"};

  }
`;
const CoinPlantImage = styled.img`
  width: 100%;
  height: 580px;
  object-fit: cover;
  border-radius: 8px;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    width: 100%;
    height: 550px;
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 100%;
    height: 530px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 430px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 360px;
  }
`;
const ReadMoreDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 4px;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;
const CustomHeading3 = styled(Heading3)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 40px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 30px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
// const CustomParagraph2 = styled(Paragraph2)`
//   @media (max-width: ${BREAKPOINTS.xl}px) {
//     font-size: 12px;
//   }
//   @media (max-width: ${BREAKPOINTS.lg}px) {
//     display: none;
//   }
// `;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 19px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }
`;
const TopReadMoreDiv = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: flex;
    justify-content: end;
    align-items: flex-end;
  }
`;
const BottomReadMoreDiv = styled.div`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const Index = () => {
  const mode = useMode();
  const [isMouseHovered, setIsMouseHovered] = useState(false);
  const [blogData,setBlogData]= useState<any[]>([]);

  const length = blogs.length
  useEffect(() => {
    const sortedBlogs = blogs?.sort((a: any, b: any) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    setBlogData(sortedBlogs);
  }, []);
  // const cleanHtmlContent = (htmlContent:any) => {
  //   if (!htmlContent) return "";
  //   return htmlContent.replace(/<br\s*\/?>/gi, "");
  // };

  return (
    <MainDiv>
      <TextDiv>
        <HeadingDiv>
          <CustomHeading3 mode={mode}>Blogs</CustomHeading3>
        </HeadingDiv>
        <SubHeadingDiv>
          <TopReadMoreDiv>
            <Link
              style={{ textDecoration: "none" }}
              to={"/blog/page/1"}
              onClick={() => window.scrollTo(0, 0)}
            >
              <ReadMoreDiv
                onMouseEnter={() => setIsMouseHovered(true)}
                onMouseLeave={() => setIsMouseHovered(false)}
              >
                <BoldParagraph3
                  mode={mode}
                  style={{ color: `${isMouseHovered ? "#03C988" : ""}` }}
                >
                  Read more
                </BoldParagraph3>
                <img
                  src={
                    isMouseHovered
                      ? rightArrowHovered
                      : mode === MODE.DARK
                        ? rightWhiteArrow
                        : rightArrow
                  }
                  alt=""
                />
              </ReadMoreDiv>
            </Link>
          </TopReadMoreDiv>
        </SubHeadingDiv>
      </TextDiv>
      <ImagesDiv>
        <LeftImageBox>
        <Link
          to={`/detail/${blogData[0]?.permalink}`}
          onClick={() => window.scrollTo(0, 0)}
          key={blogData[0]?.id}
        >
            <CoinPlantImage loading="lazy" src={blogData[0]?.imageUrl} alt="" />
          </Link>
          <CustomHeading6 mode={mode}>
            {
            //  blogData[0]?.title?.length > 130 ? (
            //     blogData[0]?.title.substr(0, 130) + " ...."
            //   ) : 
              (
                blogData[0]?.title
              )
            }
          </CustomHeading6>
          <TagBox>
          {blogData[0]?.category?.map((data: any, index: number) =>
            (
              <Tag mode={mode} key={index}>
                {data}
              </Tag>
            )
          )}
        </TagBox>
        </LeftImageBox>
        {/*  */}
        <RightImageBox>
          <RightImageDiv>
          <Link
            to={`/detail/${blogData[1]?.permalink}`}
            onClick={() => window.scrollTo(0, 0)}
            key={blogData[1]?.id}
          >
              <Image length={length} src={blogData[1]?.imageUrl} loading="lazy" alt="" />
            </Link>
            <CustomHeading6 mode={mode}>
              {
              // blogData[1]?.title?.length > 40 ? (
              //     blogData[1]?.title.substr(0, 37) + " ...."
              //   ) :
                 (
                  blogData[1]?.title
                )
              }
            </CustomHeading6>
            <TagBox>
            {blogData[1]?.category?.map((data: any, index: number) =>
              (
                <Tag mode={mode} key={index}>
                  {data}
                </Tag>
              )
            )}
          </TagBox>
          </RightImageDiv>
          { length>2 && 
          <RightImageDiv>
          <Link
            to={`/detail/${blogData[2]?.permalink}`}
            onClick={() => window.scrollTo(0, 0)}
            key={blogData[2]?.id}
          >
              <Image loading="lazy" length={length} src={blogData[2]?.imageUrl} alt="" />
            </Link>
            <CustomHeading6 mode={mode}>
              {
                  // blogData[2]?.title?.length > 40 ? (
                  //   blogData[2]?.title.substr(0, 37) + " ...."
                  // ) :
                   (
                    blogData[2]?.title
                  )
            }
            </CustomHeading6>
            <TagBox>
            {blogData[2]?.category?.map((data: any, index: number) =>
               (
                 <Tag mode={mode} key={index}>
                  {data}
                </Tag>
              )
            )}
          </TagBox>
          </RightImageDiv>
}
        </RightImageBox>
      </ImagesDiv>
      <BottomReadMoreDiv>
        <Link
          style={{ textDecoration: "none" }}
          to={"/blog/page/1"}
          onClick={() => window.scrollTo(0, 0)}
        >
          <ReadMoreDiv
            onMouseEnter={() => setIsMouseHovered(true)}
            onMouseLeave={() => setIsMouseHovered(false)}
          >
            <BoldParagraph3
              mode={mode}
              style={{ color: `${isMouseHovered ? "#03C988" : ""}` }}
            >
              Read more
            </BoldParagraph3>
            <img
              src={
                isMouseHovered
                  ? rightArrowHovered
                  : mode === MODE.DARK
                    ? rightWhiteArrow
                    : rightArrow
              }
              alt=""
            />
          </ReadMoreDiv>
        </Link>
      </BottomReadMoreDiv>
    </MainDiv>
  );
};

export default Index;
