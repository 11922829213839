import React, { useState } from "react";
import styled from "styled-components";
import TextColor, { Heading3, Paragraph2 } from "../../theme/typography";
import personIcon from "../../asset/form-svgs/person-Icon.svg";
import darkPersonIcon from "../../asset/form-svgs/person-dark-icon.svg";
import personFilledIcon from "../../asset/form-svgs/person-fill.svg";
import messageIcon from "../../asset/form-svgs/message-icon.svg";
import darkMessageIcon from "../../asset/form-svgs/message-dark-icon.svg";
import messageFilledIcon from "../../asset/form-svgs/message-fill-icon.svg";
import mailIcon from "../../asset/form-svgs/mail-icon-.svg";
import darkMailIcon from "../../asset/form-svgs/dark-mail-icon.svg";
import mailFilledIcon from "../../asset/form-svgs/mail-fill-icon.svg";
// import mailWhiteIcon from "../../asset/form-svgs/mail-white-icon.svg";
// import mailDarkIcon from '../../asset/form-svgs/mail-dark-icon.svg'
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";
import {  componentColor } from "../../theme/colors";
import ConfirmationModel from "../ConfirmationModel";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { ClipLoader } from "react-spinners";

const MainDiv = styled.div<{ mode: MODE }>`
  display: flex;
  width: 55%;
  height: 489px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  gap: 114px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${(props) => (props.mode === MODE.DARK ? "#424242" : "#f5f5f5")};
  /* background: ${(props) =>
    props.mode === MODE.DARK ? "#212121" : "#f5f5f5"}; */
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 62%;
    gap: 40px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 78%;
    gap: 40px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
    gap: 20px;
    justify-content: space-between;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    flex-direction: column;
    gap: 20px;
    height: auto;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 110px;
  gap: 4px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 85%;
    align-items: center;
    margin-bottom: 0;
  }
`;
const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 93px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    align-items: center;
    gap: 80px;
  }
`;
const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
`;
const InputCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Inputfield = styled.div<{
  onNameFocus: boolean;
  onMessageFocus: boolean;
  onMailFocus: boolean;
  mode: MODE;
}>`
  display: flex;
  width: 272px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? "#d4d6d722" : "#d4d6d742"};
  border-radius: 4px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 230px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    align-items: center;
  }

  &.name {
    border-bottom: 0.5px solid
      ${(props) => (props.onNameFocus ? "#03C988" : "rgba(0, 0, 0, 0.60)")};
  }
  &.mail {
    border-bottom: 0.5px solid
      ${(props) => (props.onMailFocus ? "#03C988" : "rgba(0, 0, 0, 0.60)")};
  }
  &.message {
    padding: 10px 10px 20px 10px;
    border-bottom: 0.5px solid
      ${(props) => (props.onMessageFocus ? "#03C988" : "rgba(0, 0, 0, 0.60)")};
  }
`;
const Input = styled.input<{
  onNameFocus: boolean;
  onMailFocus: boolean;
  mode: MODE;
}>`
  width: 250px;
  height: 25px;
  outline: none;
  background: none;
  border: none;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
  /* text */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
  &.name {
    color: ${(props) =>
      props.onNameFocus ? "#03C988" : "rgba(0, 0, 0, 0.60)"};
  }
  &.mail {
    color: ${(props) =>
      props.onMailFocus ? "#03C988" : "rgba(0, 0, 0, 0.60)"};
  }
  &.name::placeholder {
    color: ${(props) =>
      props.onNameFocus
        ? "#03C988"
        : props.mode === MODE.DARK
          ? "#fafafa"
          : ""};
  }
  &.mail::placeholder {
    color: ${(props) =>
      props.onMailFocus
        ? "#03C988"
        : props.mode === MODE.DARK
          ? "#fafafa"
          : ""};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
  }
`;
const TextArea = styled.textarea<{ onMessageFocus: boolean; mode: MODE }>`
  width: 250px;
  height: 45px;
  background: none;
  outline: none;
  border: none;
  resize: none;
  color: ${(props) =>
    props.onMessageFocus ? "#03C988" : "rgba(0, 0, 0, 0.60)"};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
  &::placeholder {
    color: ${(props) =>
      props.onMessageFocus
        ? "#03C988"
        : props.mode === MODE.DARK
          ? "#fafafa"
          : ""};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
  }
`;
// const MailUsCard = styled.div<{ mode: MODE }>`
//   border-radius: 0px 8px 8px 0px;
//   background: var(--Primary-main, #03c988);
//   display: flex;
//   width: 18%;
//   padding: 16px 10px 16px 0px;
//   flex-direction: column;
//   align-items: flex-end;
//   gap: 12px;
//   flex-shrink: 0;
//   position: absolute;
//   left: 0;
//   /* text */
//   color: ${(props) => (props.mode === MODE.DARK ? "#212121" : "#fafafa")};
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 157%; /* 21.98px */
//   letter-spacing: 0.1px;
//   @media (max-width: ${BREAKPOINTS.lg}px) {
//     display: none;
//   }
// `;
// const SecondMailUsCard = styled.div<{ mode: MODE }>`
//   border-radius: 8px;
//   background: ${(props) =>
//     props.mode === MODE.DARK ? TagColor.secondary : TagColor.primary};
//   box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
//   display: none;
//   padding: 10px;
//   flex-direction: column;
//   align-items: center;
//   gap: 12px;
//   margin-top: 20px;

//   @media (max-width: ${BREAKPOINTS.lg}px) {
//     display: flex;
//     width: 192px;
//   }
// `;
// const MailBox = styled.div`
//   display: flex;
//   gap: 8px;
//   @media (max-width: ${BREAKPOINTS.lg}px) {
//     align-items: center;
//   }
// `;
const FeedbackButtonDiv = styled.div`
  padding-left: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const MessageIcon = styled.img`
  position: relative;
  bottom: 15px;
`;
const CustomHeading3 = styled(Heading3)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 30px;
    font-weight: 500;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;
const FeedBackButton = styled.button<{ mode: MODE }>`
  width: 100%;
  outline: none;
  border: none;
  display: inline-flex;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => (props.mode === MODE.DARK ? "#03B77C" : "#56dbaf")};
  &:hover {
    background-color: #03c988;
    cursor: pointer;
  }
  /* Text */
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.paragraph : TextColor.secondary};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const Index = () => {
  const mode = useMode();
  const [onNameFocus, setOnNameFocus] = useState(false);
  const [onMailFocus, setOnMailFocus] = useState(false);
  const [onMessageFocus, setOnMessageFocus] = useState(false);
  const [isModelActive, setIsModelActive] = useState(false);

  const [fullNameInput, setFullNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitButton = async () => {
    setIsLoading(true);
    if (!emailInput || !fullNameInput || !messageInput) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      toast("Fill all the details!", {
        style: {
          padding: "10px",
          marginTop: "100px",
          position: "relative",
          zIndex: "999999",
          backgroundColor:
            mode === MODE.DARK
              ? componentColor.secondary
              : componentColor.primary,
          color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
        },
        icon: "⚠️",
        duration: 5000,
        position: "top-right",
      });

      return;
    }
    try {
      await axios({
        method: "post",
        url: "https://exchange-testnet-api.buckmint.org/api/users/contact",
        data: {
          name: fullNameInput,
          email: emailInput,
          message: messageInput,
        },
      }).then(() => {
        setIsModelActive(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      });
    } catch (error) {
      if (error instanceof Error) {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        console.log("handle submit failed", error?.message);
        toast.error("Please try again!", {
          style: {
            padding: "10px",
            marginTop: "100px",
            position: "relative",
            zIndex: "999999",
            backgroundColor:
              mode === MODE.DARK
                ? componentColor.secondary
                : componentColor.primary,
            color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
          },

          duration: 5000,
          position: "top-right",
        });
      }
    }
  };

  return (
    <MainDiv id="#contactus" mode={mode}>
      {isModelActive && (
        <ConfirmationModel setIsModelActive={setIsModelActive} />
      )}

      <TextDiv>
        <CustomHeading3 mode={mode}>Contact Us</CustomHeading3>
        <CustomParagraph2 mode={mode}>
          Please fill up your details or <br />
          mail us your query at info@buckmint.org{" "}
        </CustomParagraph2>
      </TextDiv>
      <FormDiv>
        <InputBox>
          <InputCard>
            <img
              src={
                onNameFocus
                  ? personFilledIcon
                  : mode === MODE.DARK
                    ? darkPersonIcon
                    : personIcon
              }
              alt=""
            />
            <Inputfield
              mode={mode}
              className="name"
              onMailFocus={onMailFocus}
              onNameFocus={onNameFocus}
              onMessageFocus={onMessageFocus}
            >
              <Input
                onChange={(event) => setFullNameInput(event.target.value)}
                mode={mode}
                className="name"
                onMailFocus={onMailFocus}
                onNameFocus={onNameFocus}
                onBlur={() => setOnNameFocus(false)}
                onFocus={() => setOnNameFocus(true)}
                type="text"
                placeholder="Full Name"
              />
            </Inputfield>
          </InputCard>
          <InputCard>
            <img
              src={
                onMailFocus
                  ? mailFilledIcon
                  : mode === MODE.DARK
                    ? darkMailIcon
                    : mailIcon
              }
              alt=""
            />
            <Inputfield
              mode={mode}
              className="mail"
              onMailFocus={onMailFocus}
              onNameFocus={onNameFocus}
              onMessageFocus={onMessageFocus}
            >
              <Input
                onChange={(event) => setEmailInput(event.target.value)}
                mode={mode}
                className="mail"
                onMailFocus={onMailFocus}
                onNameFocus={onNameFocus}
                onBlur={() => setOnMailFocus(false)}
                onFocus={() => setOnMailFocus(true)}
                type="email"
                placeholder="example@mail.com"
              />
            </Inputfield>
          </InputCard>
          <InputCard>
            <MessageIcon
              src={
                onMessageFocus
                  ? messageFilledIcon
                  : mode === MODE.DARK
                    ? darkMessageIcon
                    : messageIcon
              }
              alt=""
            />
            <Inputfield
              mode={mode}
              onMailFocus={onMailFocus}
              onNameFocus={onNameFocus}
              onMessageFocus={onMessageFocus}
              className="message"
            >
              <TextArea
                onChange={(event) => setMessageInput(event.target.value)}
                mode={mode}
                onMessageFocus={onMessageFocus}
                onBlur={() => setOnMessageFocus(false)}
                onFocus={() => setOnMessageFocus(true)}
                placeholder="message...."
              />
            </Inputfield>
          </InputCard>
        </InputBox>
        <FeedbackButtonDiv>
          <FeedBackButton mode={mode} onClick={handleSubmitButton}>
            SUBMIT
            {isLoading && (
              <ClipLoader
                size={15}
                color={
                  mode === MODE.DARK
                    ? componentColor.secondary
                    : componentColor.primary
                }
              />
            )}
          </FeedBackButton>
        </FeedbackButtonDiv>
      </FormDiv>
      <Toaster />
    </MainDiv>
  );
};

export default Index;
