import React from "react";
import styled from "styled-components";
import { Heading5 } from "../../theme/typography";
import BackedByIcon from "../../asset/svgs/100xIcon.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div<{ mode: MODE }>`
  width: 78%;
  background: ${(props) => (props.mode === MODE.DARK ? "#424242" : "#E0E0E0")};
  display: flex;
  border-radius: 8px;
  padding: 40px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    gap: 25px;
  }
`;
const Image = styled.img`
  /* width: 140.596px; */
  height: 36px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    /* width: 100px; */
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    /* width: 94px; */
  }
`;
const CustomHeading5 = styled(Heading5)`
  font-size: 16px;
  font-weight: 500;
  /* @media (max-width: ${BREAKPOINTS.md}px){
   font-weight: 500;
   font-size: 20px;
  } */
`;
const Index = () => {
  const mode = useMode();
  return (
    <MainDiv mode={mode}>
      <CustomHeading5 mode={mode}>Backed By </CustomHeading5>
      <Image src={BackedByIcon} alt="" />
    </MainDiv>
  );
};

export default Index;
