export const bgColor = {
  primary: "#FAFAFA",
  secondary: "#212121",
};
export const componentColor = {
  primary: "#F5F5F5",
  secondary: "#424242",
};
export const TagColor = {
  primary: "#EEE",
  hovered: "#E0E0E0",
  secondary: "#616161",
};
