import React from "react";
import styled, { keyframes } from "styled-components";
import { Paragraph3 } from "../../theme/typography";
import closeIconLight from "../../asset/svgs/close-icon-light.svg";
import closeIconDark from "../../asset/svgs/close-icon-dark.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  width: 78%;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background: var(--Primary-main, #03c988);
  position: fixed;
  z-index: 999;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    padding: 4px 12px;
  }
`;
const TextScrollAnimation = keyframes` 
    100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
`;

const TextDiv = styled.div`
  width: 93%;
  height: 20px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
`;
const CustomParagraph3 = styled(Paragraph3)`
  @media (max-width: 700px) {
    font-size: 12px;
    animation: ${TextScrollAnimation} 8s infinite linear;
  }
`;
const Image = styled.img`
  cursor: pointer;
  margin-left: auto;
  position: relative;
  z-index: 9999;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 20px;
    height: 20px;
  }
`;

interface props {
  setShowAnnouncement: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ setShowAnnouncement }: props) => {
  const setAnnouncementHandler = () => {
    setShowAnnouncement(false);
    console.log("announcement close called");
  };
  const mode = useMode();
  return (
    <MainDiv>
      <TextDiv>
        <CustomParagraph3
          mode={mode}
          style={{ color: mode === MODE.DARK ? "#212121" : "#FAFAFA" }}
        >
          Announcement : Buckmint - The Decentralized Exchange for Carbon
          Credits
        </CustomParagraph3>
      </TextDiv>
      <Image
        onClick={() => setAnnouncementHandler()}
        src={mode === MODE.DARK ? closeIconDark : closeIconLight}
        alt=""
      />
    </MainDiv>
  );
};

export default Index;
