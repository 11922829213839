import React, { useState } from "react";
import styled from "styled-components";
import {
  Paragraph1b,
  Paragraph2,
} from "../../theme/typography";
import plusIcon from "../../asset/svgs/faq-plus- Icon.svg";
import plusWhiteIcon from "../../asset/svgs/faq-plus-white-icon.svg";
import minusIcon from "../../asset/svgs/faq-minus-icon.svg";
import minusWhiteIcon from "../../asset/svgs/faq-minus-white-icon.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 78%;
  gap: 40px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 56px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-left: 0;
  }
`;
const PointsDiv = styled.div`
  display: flex;
  gap: 12px;
  cursor: pointer;
`;

const DetailDiv = styled.div<{ mode: MODE }>`
  width: 90%;
  margin-left: 36px;
  padding-left: 20px;
  border-left: 1px solid
    ${(props) => (props.mode === MODE.DARK ? "#FFFFFFDE" : "#000")};
`;
const PlusIcon = styled.img`
  height: 24px;
  width: 24px;
`;

const CustomParagraph1b = styled(Paragraph1b)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 15px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 14px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
  }
`;
const Index = () => {
  const mode = useMode();
  const [isQuestionClicked, setIsQuestionClicked] = useState(0);
  // const [isMouseHovered, setIsMouseHovered] = useState(false);

  return (
    <MainDiv>
      <ContentBox>
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 1 ? 0 : 1)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 1
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            What is a gasless decentralized exchange?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 1 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 2 ? 0 : 2)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 2
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            How does a gasless decentralized exchange work?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 2 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 3 ? 0 : 3)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 3
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Why is gasless trading important for climate assets?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 3 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 4 ? 0 : 4)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 4
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            What types of climate assets can I trade on the gasless
            decentralized exchange?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 4 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 5 ? 0 : 5)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 5
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Is the gasless decentralized exchange secure?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 5 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 6 ? 0 : 6)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 6
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            How do I start trading on the gasless decentralized exchange?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 6 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 7 ? 0 : 7)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 7
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Do I need to create an account to use the gasless decentralized
            exchange?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 7 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 8 ? 0 : 8)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 8
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Why is gasless trading important for climate assets?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 8 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() => setIsQuestionClicked(isQuestionClicked === 9 ? 0 : 9)}
        >
          <PlusIcon
            src={
              isQuestionClicked === 9
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Can I withdraw my assets from the gasless decentralized exchange at
            any time?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 9 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() =>
            setIsQuestionClicked(isQuestionClicked === 10 ? 0 : 10)
          }
        >
          <PlusIcon
            src={
              isQuestionClicked === 10
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Are there any withdrawal fees for removing assets from the gasless
            decentralized exchange?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 10 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() =>
            setIsQuestionClicked(isQuestionClicked === 11 ? 0 : 11)
          }
        >
          <PlusIcon
            src={
              isQuestionClicked === 11
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            How does the gasless decentralized exchange ensure liquidity for
            trading?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 11 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() =>
            setIsQuestionClicked(isQuestionClicked === 12 ? 0 : 12)
          }
        >
          <PlusIcon
            src={
              isQuestionClicked === 12
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Can I earn rewards for providing liquidity on the gasless
            decentralized exchange?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 12 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <PointsDiv
          onClick={() =>
            setIsQuestionClicked(isQuestionClicked === 13 ? 0 : 13)
          }
        >
          <PlusIcon
            src={
              isQuestionClicked === 13
                ? mode === MODE.DARK
                  ? minusWhiteIcon
                  : minusIcon
                : mode === MODE.DARK
                  ? plusWhiteIcon
                  : plusIcon
            }
            alt=""
          />
          <CustomParagraph1b mode={mode}>
            Is the gasless decentralized exchange compatible with all
            Ethereum-based wallets?
          </CustomParagraph1b>
        </PointsDiv>
        {isQuestionClicked === 13 && (
          <DetailDiv mode={mode}>
            <CustomParagraph2 mode={mode}>
              A gasless decentralized exchange is a trading platform where users
              can exchange assets without needing to pay gas fees typically
              associated with blockchain transactions.
            </CustomParagraph2>
          </DetailDiv>
        )}
        <Link style={{ textDecoration: "none" }} to={"/faqs"}></Link>
      </ContentBox>
    </MainDiv>
  );
};

export default Index;
