import React from "react";
import styled from "styled-components";
import crossiconLight from "../../../asset/svgs/cross-icon-light.svg";
import crossiconDark from "../../../asset/svgs/cross-icon-dark.svg";
import { useMode } from "../../../store/mode/hooks";
import { MODE } from "../../../store/mode";
import logo from "../../../asset/header-icons/logo.svg";
import logoName from "../../../asset/header-icons/logo-name.svg";
import { BoldParagraph2 } from "../../../theme/typography";
import LaunchButton from "../../Buttons/LaunchButton";
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../../../theme/breakpoints";
import { bgColor } from "../../../theme/colors";
const MainDiv = styled.div<{ mode: MODE }>`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0px 8px 8px 0px;
  background: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  box-shadow: 0px 4px 11.7px 0px rgba(0, 0, 0, 0.34);
  position: relative;
  flex-direction: column;
`;
const ContentBox = styled.div`
  margin-top: 100px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 60px;
`;
const CrossIcon = styled.img`
  position: absolute;
  top: 29px;
  right: 15px;
  cursor: pointer;
`;
const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const LogoImg = styled.img`
  width: 39.874px;
  height: 40px;
  @media (min-width: ${BREAKPOINTS.xs}px) {
    width: 50.874px;
    height: 50px;
  }
`;
const LogoNameImg = styled.img`
  width: 135.844px;
  height: 13.439px;
  @media (min-width: ${BREAKPOINTS.xs}px) {
    height: 70px;
  }
`;
const OptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;
const LaunchButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: auto;
  padding-bottom: 80px;
`;
const CustomBoldParagraph2 = styled(BoldParagraph2)`
  @media (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }
`;
interface props {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const Sidebar = ({ setIsMenuOpen }: props) => {
  const mode = useMode();
  return (
    <MainDiv mode={mode}>
      <CrossIcon
        onClick={() => setIsMenuOpen(false)}
        src={mode === MODE.DARK ? crossiconDark : crossiconLight}
        alt=""
      />
      <ContentBox>
        <LogoDiv>
          <LogoImg src={logo} alt="" />
          <LogoNameImg src={logoName} alt="" />
        </LogoDiv>
        <OptionDiv>
          <Link
            to={"/about"}
            style={{ textDecoration: "none" }}
            onClick={() => window.scrollTo(0, 0)}
          >
            <CustomBoldParagraph2 mode={mode}>About Us</CustomBoldParagraph2>
          </Link>
          <Link to={"/blog"} style={{textDecoration:"none"}} onClick={ ()=>window.scrollTo(0,0)}>
          <CustomBoldParagraph2 mode={mode}>Blogs</CustomBoldParagraph2>
          </Link>
          {/* <Link to={"/"} style={{textDecoration:"none"}} onClick={ ()=>window.scrollTo(0,0)}>
          <CustomBoldParagraph2 mode={mode}>Community</CustomBoldParagraph2>
          </Link> */}
          {/* <Link to={"/"} style={{textDecoration:"none"}} onClick={ ()=>window.scrollTo(0,0)}>
          <CustomBoldParagraph2 mode={mode}>Docs</CustomBoldParagraph2>
          </Link> */}
          <Link
            to={"/privacypolicy"}
            style={{ textDecoration: "none" }}
            onClick={() => window.scrollTo(0, 0)}
          >
            <CustomBoldParagraph2 mode={mode}>
              Privacy Policy
            </CustomBoldParagraph2>
          </Link>
          <Link
            to={"/termsandcondition"}
            style={{ textDecoration: "none" }}
            onClick={() => window.scrollTo(0, 0)}
          >
            <CustomBoldParagraph2 mode={mode}>
              Terms of Use
            </CustomBoldParagraph2>
          </Link>
          {/* <Link to={"/faqs"} style={{textDecoration:"none"}} onClick={ ()=>window.scrollTo(0,0)}>
          <CustomBoldParagraph2 mode={mode}>FAQs</CustomBoldParagraph2>
          </Link> */}
        </OptionDiv>
      </ContentBox>
      <LaunchButtonDiv>
        <LaunchButton />
      </LaunchButtonDiv>
    </MainDiv>
  );
};

export default Sidebar;
