import React from "react";
import "./App.css";
import Home from "./pages/Home";
import { Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Blogs from "./pages/Blogs";
import Faqs from "./pages/Faqs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandCondition from "./pages/TermsAndCondition";
import DetailBlog from "./pages/DetailBlogPage/DetailBlog";
import { useSystempreferenceMode } from "./store/mode/hooks";
import { HelmetProvider } from "react-helmet-async";

function App() {
  useSystempreferenceMode();
  
 
  return (
    <div>
      <HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blog" element={<Navigate to="/blog/page/1" />} />
        <Route path="/blog/page/:page" element={<Blogs />} />
        <Route path="/detail/:permalink" element={<DetailBlog />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandcondition" element={<TermsandCondition />} />
      </Routes>
      </HelmetProvider>
    </div>
  );
}

export default App;
