import React, { useState } from "react";
import styled from "styled-components";
import logo from "../../../asset/header-icons/logo.svg";
import logoName from "../../../asset/header-icons/logo-name.svg";
import { isMobile, isTablet } from "react-device-detect";
import TextColor, {
  Paragraph1,
  Paragraph2,
  Paragraph3,
} from "../../../theme/typography";
import discordIcon from "../../../asset/socials/discord-logo.svg";
import linkedinIcon from "../../../asset/socials/linkedin-logo.svg";
import telegramIcon from "../../../asset/socials/telegram-logo.svg";
import twitterIcon from "../../../asset/socials/twitter-logo.svg";
import mailIcon from "../../../asset/svgs/mail-icon.svg";
import { Link } from "react-router-dom";
import { useMode } from "../../../store/mode/hooks";
import { BREAKPOINTS } from "../../../theme/breakpoints";
// import circleArrow from "../../../asset/pngs/circle-arrow.png";
import circleArrowWhite from "../../../asset/pngs/circle-arrow-white.png";
import { MODE } from "../../../store/mode";
import toast from "react-hot-toast";
import { componentColor } from "../../../theme/colors";
import axios from "axios";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  padding: 70px 120px 0px 120px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 0;
  height: 250px;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    padding: 70px 100px 0px 100px;
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 60px 60px 0px 60px;
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
    padding: 60px 40px 0px 40px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    height: 600px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 40px 30px 0px 30px;
  }
`;
const ContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 87px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;
const Section1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 30px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 27px;
  }
`;
const Section3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 35%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const LogoDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const LogoImage = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 40px;
    width: 40px;
  }
`;
const LogoNameImage = styled.img`
  width: 151.628px;
  height: 15px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 140px;
  }
`;
const LinkDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const LinkBox = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const SocialDiv = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 600px) {
    display: none;
  }
`;
const MobileSocialDiv = styled.div`
  display: none;
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
`;
const SocialIcons = styled.img`
  cursor: pointer;
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 10px;
    width: 100%;
  }
`;

const MailInputBox = styled.div`
  display: flex;
  /* width: 307px; */
  width: 87%;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--layers-layer-3200700, #616161);
  /* text */
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.6));
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: 0.46px;
  position: relative;
`;
const Input = styled.input`
  outline: none;
  border: none;
  background: none;
  color: white;
  width: 100%;
  /* text */
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.6));
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: 0.46px;
`;
const CustomParagraph1 = styled(Paragraph1)`
  font-size: 14px;
  font-weight: 500;
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 12px;
  }
`;
const ArrowIcon = styled.img`
  position: absolute;
  right: -40px;
  transform: rotate(180deg);
  cursor: pointer;
`;
const ChartInfo = styled.div`
  /* position: absolute; */
  display: flex;
  width: 100%;
  justify-content: center;
  margin-right: 80px;
@media (max-width: 1250px){
  margin-right: 20px;
}
`
const Index = () => {
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const mode = useMode();
  const [mailInput, setMailInput] = useState<string>();

  const handleInputSubmit = async () => {
    if (!mailInput) {
      console.log("email not found");
      toast("Please enter Email!", {
        style: {
          padding: "10px",
          marginTop: "100px",
          backgroundColor:
            mode === MODE.DARK
              ? componentColor.secondary
              : componentColor.primary,
          color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
        },
        icon: "⚠️",
        duration: 5000,
        position: "top-right",
      });

      return;
    }
    try {
      await axios({
        method: "post",
        url: "https://exchange-testnet-api.buckmint.org/api/feedback/subscribe",
        data: {
          email: mailInput,
        },
      }).then(() => {
        toast.success("Subscribed", {
          style: {
            padding: "10px",
            marginTop: "100px",
            backgroundColor:
              mode === MODE.DARK
                ? componentColor.secondary
                : componentColor.primary,
            color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
          },
          duration: 5000,
          position: "top-right",
        });
      });
    } catch (error) {
      if (error instanceof Error) {
        console.log("handle submit failed", error?.message);
        toast.error("Couldn't Subscribed!", {
          style: {
            padding: "10px",
            marginTop: "100px",
            backgroundColor:
              mode === MODE.DARK
                ? componentColor.secondary
                : componentColor.primary,
            color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
          },
          duration: 5000,
          position: "top-right",
        });
      }
    }
  };
  return (
    <MainDiv>
      <ContentDiv>
        <Section1>
          <Link
            style={{ textDecoration: "none" }}
            to={"/"}
            onClick={() => window.scrollTo(0, 0)}
          >
            <LogoDiv>
              <LogoImage src={logo} alt="" />
              <LogoNameImage src={logoName} alt="" />
            </LogoDiv>
          </Link>
          <Paragraph3
            mode={mode}
            style={{ color: "rgba(255, 255, 255, 0.60)" }}
          >
            ©2023 Buckmint.org
          </Paragraph3>
        </Section1>
        <Section2>
          <LinkDiv>
            <LinkBox>
              <Link
                style={{ textDecoration: "none" }}
                to={"/about"}
                onClick={() => window.scrollTo(0, 0)}
              >
                <CustomParagraph2
                  mode={mode}
                  style={{ color: "rgba(255, 255, 255, 0.60)" }}
                >
                  About us
                </CustomParagraph2>
              </Link>
            </LinkBox>
            <LinkBox>
              <Link
                style={{ textDecoration: "none" }}
                to={"/about"}
                onClick={
                  isMobile
                    ? () => window.scrollTo(0, 1300)
                    : isTablet
                      ? () => window.scrollTo(0, 1000)
                      : () => window.scrollTo(0, 1000)
                }
              >
                <CustomParagraph2
                  mode={mode}
                  style={{ color: "rgba(255, 255, 255, 0.60)" }}
                >
                  Contact us
                </CustomParagraph2>
              </Link>
            </LinkBox>
            <LinkBox>
            <Link style={{textDecoration:'none'}} to={'/blog'} onClick={ ()=>window.scrollTo(0,0)}> 
              <CustomParagraph2 mode={mode} style={{ color: "rgba(255, 255, 255, 0.60)" }}>
                Blogs
              </CustomParagraph2>
              </Link>
            </LinkBox>
            {/* <LinkBox>
             <Link style={{textDecoration:'none'}} to={'/faqs'} onClick={ ()=>window.scrollTo(0,0)}> 
             <CustomParagraph2 mode={mode} style={{ color: "rgba(255, 255, 255, 0.60)" }}>
                FAQs
              </CustomParagraph2>
              </Link>
            </LinkBox> */}
            <LinkBox>
              <Link
                style={{ textDecoration: "none" }}
                to={"/privacypolicy"}
                onClick={() => window.scrollTo(0, 0)}
              >
                <CustomParagraph2
                  mode={mode}
                  style={{ color: "rgba(255, 255, 255, 0.60)" }}
                >
                  Privacy Policy
                </CustomParagraph2>
              </Link>
            </LinkBox>
            <LinkBox>
              <Link
                style={{ textDecoration: "none" }}
                to={"/termsandcondition"}
                onClick={() => window.scrollTo(0, 0)}
              >
                <CustomParagraph2
                  mode={mode}
                  style={{ color: "rgba(255, 255, 255, 0.60)" }}
                >
                  Terms & Conditions
                </CustomParagraph2>
              </Link>
            </LinkBox>
            {/* <LinkBox>
              <CustomParagraph2 mode={mode} style={{ cursor:"pointer", color: "rgba(255, 255, 255, 0.60)" }}>
                Audit Report
              </CustomParagraph2>
            </LinkBox> */}
          </LinkDiv>
          <SocialDiv>
            <a
              href="https://discord.com/invite/XSR8WtyDmB"
              aria-label="Discord"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcons src={discordIcon} alt="" />{" "}
            </a>
            <a
              href="https://t.me/Buckmint_chat"
              aria-label="Telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcons src={telegramIcon} alt="" />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/buckmint/"
              aria-label="Linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcons src={linkedinIcon} alt="" />{" "}
            </a>
            <a
              href="https://twitter.com/buckmint"
              aria-label="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <SocialIcons src={twitterIcon} alt="" />{" "}
            </a>
          </SocialDiv>
        </Section2>
        <Section3>
          <TextDiv>
            <CustomParagraph1
              mode={mode}
              style={{ color: "rgba(255, 255, 255, 0.87)" }}
            >
              Get News Directly
            </CustomParagraph1>
            <Paragraph2
              mode={mode}
              style={{ color: "rgba(255, 255, 255, 0.60)" }}
            >
              Sign up for our newsletter for timely updates.
            </Paragraph2>
            <MailInputBox>
              {!inputFocus && (
                <>
                  <img src={mailIcon} alt="" />
                </>
              )}
              {/* <form action=""> */}
              <Input
                onChange={(e) => {
                  setMailInput(e.target.value);
                  e.preventDefault();
                }}
                onBlur={() => setInputFocus(false)}
                placeholder="example@mail.com"
                onFocus={() => setInputFocus(true)}
                type="email"
              />
              {/* </form> */}
              <ArrowIcon
                onClick={handleInputSubmit}
                src={circleArrowWhite}
                alt=""
              />
            </MailInputBox>
          </TextDiv>
        </Section3>
      </ContentDiv>
      <MobileSocialDiv>
      <a
              href="https://discord.com/invite/XSR8WtyDmB"
              aria-label="Discord"
              target="_blank"
              rel="noopener noreferrer"
            >
        <SocialIcons src={discordIcon} alt="" />
        </a>
        <a
              href="https://t.me/Buckmint_chat"
              aria-label="Telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
        <SocialIcons src={telegramIcon} alt="" />
        </a>
        <a
          href="https://www.linkedin.com/company/buckmint/"
          aria-label="Linkedin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcons src={linkedinIcon} alt="" />
        </a>
        <a
          href="https://twitter.com/buckmint"
          aria-label="Twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcons src={twitterIcon} alt="" />
        </a>
      </MobileSocialDiv>

      <ChartInfo>
       <Paragraph3 style={{color:"rgba(255,255,255,0.60)"}} mode ={mode }>
        Charts powered by   <a
        style={{color:"#39ace7"}}
              href="https://www.tradingview.com/"
              aria-label="Discord"
              target="_blank"
              rel="noopener noreferrer"
            > Tradingview </a>
       </Paragraph3>
      </ChartInfo>
    </MainDiv>
  );
};

export default Index;
