import React from "react";
import styled from "styled-components";
import { Heading4 } from "../../theme/typography";
import { useMode } from "../../store/mode/hooks";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  /* height: 100%; */
  /* border: 1px solid; */
  gap: 12px;
  text-align: center;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 80%;
    text-align: center;
  }
`;
const CustomHeading4 = styled(Heading4)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
const Index = () => {
  const mode = useMode();

  return (
    <MainDiv>
      <CustomHeading4 mode={mode}>Key Features</CustomHeading4>
      {/* <Paragraph2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et aliquam orci, eu pretium dolor. Cras viverra mi ligula, ut imperdiet ex dapibus sit amet. Vivamus neque purus, cursus sit amet justo in, scelerisque accumsan purus. Curabitur urna justo, maximus et neque pretium, rutrum dignissim quam.</Paragraph2> */}
    </MainDiv>
  );
};

export default Index;
