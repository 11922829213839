import React from "react";
import styled from "styled-components";
import { Heading4, Paragraph1, Paragraph1b } from "../../theme/typography";
import ourPlatformIcon from "../../asset/ourPlateformIcon/our-platform-icon.svg";
import securityIcon from "../../asset/ourPlateformIcon/security-icon.svg";
import immutableIcon from "../../asset/ourPlateformIcon/immutable-icon.svg";
import interConnectedIcon from "../../asset/ourPlateformIcon/inter-connected-icon.svg";
import noFailedOrderIcon from "../../asset/ourPlateformIcon/no-failed-order-icon.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { motion } from "framer-motion";
import { componentColor } from "../../theme/colors";
import { BREAKPOINTS } from "../../theme/breakpoints";
import { isBrowser } from "react-device-detect";

const MainDiv = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const Heading = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  width: 27%;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 31%;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 33%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    gap: 20px;
  }
`;
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  width: 27%;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 31%;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 33%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    gap: 20px;
  }
`;
const MainImage = styled.img`
  width: 288px;
  height: 271.254px;
  flex-shrink: 0;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 220px;
    height: 210px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 200px;
    height: 190px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 150px;
    height: 140px;
    display: none;
  }
`;
const DetailCard = styled.div<{ mode: MODE }>`
  display: flex;
  width: 100%;
  /* height: 172px; */
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: ${(props) =>
    props.mode === MODE.DARK
      ? componentColor.secondary
      : componentColor.primary};
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 12px;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 6px;
  }
`;
const CustomHeadin4 = styled(Heading4)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
const CustomParagraph1 = styled(Paragraph1)``;
const CustomParagraph1b = styled(Paragraph1b)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 14px;
  }
`;
const UpperSection = styled.div`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
    align-items: center;
    gap: 7px;
  }
`;
const ImageDiv = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const MainMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 150px;
    height: 140px;
    display: flex;
    justify-content: center;
  }
`;
const Index = () => {
  const mode = useMode();
  return (
    <MainDiv>
      <Heading>
        <CustomHeadin4 mode={mode}>Our Platform</CustomHeadin4>
      </Heading>
      <ContentBox>
        <ImageDiv>
          <MainMobileImage src={ourPlatformIcon} alt="" />
        </ImageDiv>
        <LeftSection
          as={motion.div}
          initial={{ translateX: "-200px", opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          viewport={{ once: true, amount: isBrowser? 0.5 : 0 }}
          transition={{
            ease: "easeInOut",
            delay: 0.4,
            duration: 1.5,
            type: "spring",
          }}
        >
          <DetailCard mode={mode}>
            <TextBox>
              <UpperSection>
                <img src={immutableIcon} alt="" />
                <CustomParagraph1 style={{ color: "#03C988" }} mode={mode}>
                  Immutable
                </CustomParagraph1>
              </UpperSection>
              <CustomParagraph1b mode={mode}>
                Orders cannot be altered, ensuring the integrity and accuracy of
                trading
              </CustomParagraph1b>
            </TextBox>
          </DetailCard>
          {/*  */}
          <DetailCard mode={mode}>
            <TextBox>
              <UpperSection>
                <img src={interConnectedIcon} alt="" />
                <CustomParagraph1 style={{ color: "#03C988" }} mode={mode}>
                  Inter connected
                </CustomParagraph1>
              </UpperSection>
              <CustomParagraph1b mode={mode}>
                Fostering seamless communication and collaboration
              </CustomParagraph1b>
            </TextBox>
          </DetailCard>
        </LeftSection>
        <MainImage src={ourPlatformIcon} alt="" />
        <RightSection
          as={motion.div}
          initial={{ translateX: "200px", opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          viewport={{ once: true , amount: isBrowser? 0.5 : 0 }}
          transition={{
            ease: "easeInOut",
            delay: 0.4,
            duration: 1.5,
            type: "spring",
          }}
        >
          <DetailCard mode={mode}>
            <TextBox>
              <UpperSection>
                <img src={noFailedOrderIcon} alt="" />
                <CustomParagraph1 style={{ color: "#03C988" }} mode={mode}>
                  0 failed order
                </CustomParagraph1>
              </UpperSection>
              <CustomParagraph1b mode={mode}>
                Flawless transaction process and seamless operations
              </CustomParagraph1b>
            </TextBox>
          </DetailCard>
          {/*  */}
          <DetailCard mode={mode}>
            <TextBox>
              <UpperSection>
                <img src={securityIcon} alt="" />
                <CustomParagraph1 style={{ color: "#03C988" }} mode={mode}>
                  Security
                </CustomParagraph1>
              </UpperSection>
              <CustomParagraph1b mode={mode}>
                Ensuring integrity and confidentiality of trading data
              </CustomParagraph1b>
            </TextBox>
          </DetailCard>
        </RightSection>
      </ContentBox>
    </MainDiv>
  );
};

export default Index;
