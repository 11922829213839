import React from "react";
import styled from "styled-components";
import communityImage from "../../asset/images/community-image.jpeg";
import { Heading6, Paragraph1b, Paragraph3 } from "../../theme/typography";
import discordIcon from "../../asset/svgs/discord-icon.svg";
import telegramIcon from "../../asset/svgs/telegram-icon.svg";
import { useMode } from "../../store/mode/hooks";
import { BREAKPOINTS } from "../../theme/breakpoints";
const MainDiv = styled.div`

  width: 78%;
  height: 120px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
    height: 160px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
    height: 130px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    height: 170px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
`;
const ContentDiv = styled.div`
  background: rgba(3, 201, 136, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  z-index: 99;
  padding: 8px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 8px 20px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`;
const TextDiv = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 37%;
  @media (max-width: ${BREAKPOINTS.xxxl}px) {
    gap: 12px;
    width: 50%;
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    gap: 8px;
    width: 60%;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 8px;
    width: 40%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 8px;
    width: 45%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 55%;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 90%;
    text-align: center;
  }
`;
const SocialLogoDiv = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 40px;
  }
`;
const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const SocialIcon = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 40px;
    height: 40px;
  }
`;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 157%; /* 21.98px */
    letter-spacing: 0.1px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 16px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 157%; /* 21.98px */
    letter-spacing: 0.1px;
  }
`;

const CustomParagraph1b = styled(Paragraph1b)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 14px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }
`;
const Index = () => {
  const mode = useMode();
  return (
    <MainDiv>
      <Image src={communityImage} alt="" />
      <ContentDiv>
        <TextDiv>
          <CustomHeading6 mode={mode} style={{ color: "#FFF" }}>
            Join Us in Building a Greener Future
          </CustomHeading6>
          <CustomParagraph1b mode={mode} style={{ color: "#FFF" }}>
            Collaborate with us to create a more sustainable world through
            climate asset trading.
          </CustomParagraph1b>
        </TextDiv>
        <SocialLogoDiv>
          <a
            href="https://discord.com/invite/XSR8WtyDmB"
            aria-label="Discord"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Social>
              <SocialIcon src={discordIcon} alt="" />
              <Paragraph3 mode={mode} style={{ color: "#FFF" }}>
                Discord
              </Paragraph3>
            </Social>
          </a>
          <a
            href="https://t.me/Buckmint_chat"
            aria-label="Telegram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Social>
              <SocialIcon src={telegramIcon} alt="" />
              <Paragraph3 mode={mode} style={{ color: "#FFF" }}>
                Telegram
              </Paragraph3>
            </Social>
          </a>
        </SocialLogoDiv>
      </ContentDiv>
    </MainDiv>
  );
};

export default Index;
