import React from "react";
import styled from "styled-components";
import { MODE } from "../../../store/mode";
import { useMode } from "../../../store/mode/hooks";
import closeIconLight from "../../../asset/svgs/close-icon-light.svg";
import closeIconDark from "../../../asset/svgs/close-icon-dark.svg";
import whatsappIcon from "../../../asset/pngs/whatsapp-icon.png";
import copyIcon from "../../../asset/pngs/copy-icon.png";
import mailIcon from "../../../asset/pngs/mail-icon.png";
import { Paragraph2, Paragraph3b } from "../../../theme/typography";
import { CopyToClipboard } from "react-copy-to-clipboard";

const MainDiv = styled.div<{ mode: MODE }>`
  height: auto;
  width: 300px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? "#212121db" : "#fafafaf2"};
  border-radius: 8px;
  padding: 20px;
`;
const CloseIcon = styled.img<{ mode: MODE }>`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 26px;
  padding: 4px;
  &:hover {
    border-radius: 100%;
    background-color: ${(props) =>
      props.mode === MODE.DARK ? "#646262" : "#dbd6d6f4"};
  }
`;
const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const LinkContainer = styled.div`
  width: 100%;
  justify-content: space-around;
  display: flex;
  margin-top: 20px;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 80px;
`;
const Icons = styled.img`
  height: 28px;
  width: 28px;
  cursor: pointer;
  &:active {
    transform: scale(0.8);
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  position: absolute;
  top: 0px;
  left: 0px;
`;
const BlogDetailDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  position: relative;
`;
const SmallImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 8px;
`;
const MidLine = styled.div<{ mode: MODE }>`
  width: 100%;
  height: 1px;
  background-color: ${(props) =>
    props.mode === MODE.LIGHT ? "#2121217f" : "#fafafaa4"};
  margin-top: 20px;
  margin-bottom: 20px;
`;

interface props {
  setShareCardActive: React.Dispatch<React.SetStateAction<boolean>>;
  image: any;
  title: any;
}
const ShareCard = ({ setShareCardActive, title, image }: props) => {
  const mode = useMode();

  return (
    <MainDiv mode={mode}>
      <CloseIcon
        onClick={() => setShareCardActive(false)}
        mode={mode}
        src={mode === MODE.LIGHT ? closeIconDark : closeIconLight}
        alt=""
      />

      <InfoCard>
        <Paragraph2
          mode={mode}
          style={{
            color: `${
              mode === MODE.DARK ? "rgba(255,255,255,0.87)" : "rgba(0,0,0,0.87)"
            }`,
          }}
        >
          Share
        </Paragraph2>
        <BlogDetailDiv>
          <CustomParagraph2 mode={mode}> Buckmint</CustomParagraph2>
          <Paragraph3b style={{ paddingTop: "20px" }} mode={mode}>
            {title}
          </Paragraph3b>
          <SmallImage
            src={image}
            alt=""
          />
        </BlogDetailDiv>
      </InfoCard>
      <MidLine mode={mode} />
      <LinkContainer>
        <Box>
          <CopyToClipboard text={window.location.href}>
            <Icons src={copyIcon} alt="" />
          </CopyToClipboard>
          <Paragraph3b mode={mode}>Copy link</Paragraph3b>
        </Box>
        <Box>
          <a
            href={`https://wa.me/?text=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${window.location.href}`}
          >
            <Icons src={whatsappIcon} alt="" />
          </a>
          <Paragraph3b mode={mode}>Whatsapp</Paragraph3b>
        </Box>
        <Box>
          <a
            href={`
        mailto:?subject=${title}&body=${
              "I thought you would be interested in this story I found on Buckmint:  " +
              window.location.href
            }`}
            target="_blank"
            rel="noreferrer nofollow noopener"
            aria-label={`${window.location.href}`}
          >
            <Icons src={mailIcon} alt="" />
          </a>
          <Paragraph3b mode={mode}>Mail</Paragraph3b>
        </Box>
      </LinkContainer>
    </MainDiv>
  );
};

export default ShareCard;
