import React from "react";
import styled from "styled-components";
import targetIcon from "../../asset/svgs/target-svg.svg";
import { Paragraph1, Paragraph2 } from "../../theme/typography";
import { componentColor } from "../../theme/colors";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";
const MainDiv = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    width: 78%;
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    gap: 30px;
  }
  @media (max-width: 1150px) {
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const Icon = styled.img`
  @media (min-width: ${BREAKPOINTS.lg}px) {
    width: 50px;
    height: 50px;
  }
  width: 50px;
  height: 50px;
`;
const MissionDiv = styled.div<{ mode: MODE }>`
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 29px;
  text-align: center;
  border-radius: 8px;
  background: ${(props) =>
    props.mode === MODE.DARK
      ? componentColor.secondary
      : componentColor.primary};
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
  @media (max-width: ${BREAKPOINTS.xl}px) {
    gap: 20px;
  }
  @media (max-width: 1150px) {
    gap: 29px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const VisionDiv = styled.div<{ mode: MODE }>`
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 29px;
  text-align: center;
  border-radius: 8px;
  background: ${componentColor.primary};
  background: ${(props) =>
    props.mode === MODE.DARK
      ? componentColor.secondary
      : componentColor.primary};
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
  @media (max-width: ${BREAKPOINTS.xl}px) {
    gap: 20px;
  }
  @media (max-width: 1150px) {
    gap: 29px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Index = () => {
  const mode = useMode();
  return (
    <MainDiv>
      <MissionDiv mode={mode}>
        <Div1>
          <Icon src={targetIcon} alt="" />
          <Paragraph1 mode={mode}>Mission</Paragraph1>
        </Div1>
        <Paragraph2 style={{ textAlign: "justify" }} mode={mode}>
          Our mission at Buckmint is to democratize access to sustainable
          solutions, making it easier for everyone to embrace eco-friendly
          practices in their daily lives. Through our platform, we strive to
          empower individuals and businesses to reduce their environmental
          footprint and contribute to a more sustainable planet.
        </Paragraph2>
      </MissionDiv>
      <VisionDiv mode={mode}>
        <Div1>
          <Icon src={targetIcon} alt="" />
          <Paragraph1 mode={mode}>Vision</Paragraph1>
        </Div1>
        <Paragraph2 style={{ textAlign: "justify" }} mode={mode}>
          At Buckmint, we envision a world where sustainability is not just a
          choice, but a way of life. Our vision is to create a global community
          united in the pursuit of a greener, more equitable future. By
          leveraging technology and fostering collaboration, we aim to drive
          meaningful change and inspire others to join us on the journey towards
          a sustainable tomorrow.
        </Paragraph2>
      </VisionDiv>
    </MainDiv>
  );
};

export default Index;
