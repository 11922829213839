import React from "react";
import styled from "styled-components";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const Button = styled.div<{ mode: MODE }>`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(
    --Gradient-Primary-Color,
    linear-gradient(135deg, #b1eeda 0%, #028f61 100%)
  );
  /* Text */
  color: ${(props) => (props.mode === MODE.DARK ? "#212121" : "#fafafa")};
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 173.333% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
  }

  &:hover {
    background: var(--Primary-main, #03c988);
    cursor: pointer;
  }
`;
const LaunchButton = () => {
  const mode = useMode();
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSe3zLJnIVcGLagJcfXHEEJTVWpCk9KcYI0o0Jhj5Ldk4vOWZw/viewform?"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button mode={mode}>
        <p>Join Waitlist</p>
      </Button>
    </a>
  );
};

export default LaunchButton;
