import React from "react";
import styled from "styled-components";
import { bgColor } from "../../theme/colors";
import blogImage from "../../asset/images/detail-leaf.webp.webp";
import Navbar from "../../component/layouts/Navbar";
import FooterCard from "../../component/layouts/Footer";
import CommunityCard from "../../component/CommunityCard";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import DetailBlogContent from "../../component/DetailBlogContent";
import RelatedBlog from "../../component/RelatedBlog";
import { BREAKPOINTS } from "../../theme/breakpoints";

const Header = styled.header`
  width: 100%;
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  z-index: 999;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px 0;
  }
`;
const MainDiv = styled.div<{ mode: MODE }>`
  width: 100vw;
  height: auto;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: absolute;
  top: 0;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 385px;
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 330px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 280px;
  }
`;
const AboutUsImage = styled.img`
  width: 100%;
  height: 385px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 330px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 280px;
  }
`;
const BlogContentBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const RelatedBlogBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top:40px */
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 100;
`;

const CommunityBox = styled.div<{ mode: MODE }>`
  border-radius: 0px 0px 40px 40px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 99;
`;

const BelowFooterDiv = styled.div`
  background: #424242;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  top: -40px;
  background: #424242;
  display: flex;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
  }
  @media (max-width: 600px) {
    height: 600px;
  } 
`;

const DetailBlog = () => {

  const mode = useMode();
  return (
    <MainDiv mode={mode}>
      <Header>
        <Navbar/>
      </Header>
      <ImageDiv>
        <AboutUsImage src={blogImage} alt="" />
      </ImageDiv>
      <BlogContentBox mode={mode}>
        <DetailBlogContent />
      </BlogContentBox>
      <RelatedBlogBox mode={mode}>
        <RelatedBlog />
      </RelatedBlogBox>

      <CommunityBox mode={mode}>
        <CommunityCard />
      </CommunityBox>
 
      <BelowFooterDiv>
        <FooterDiv>
          <FooterCard />
        </FooterDiv>
      </BelowFooterDiv>
    </MainDiv>
  );
};

export default DetailBlog;
