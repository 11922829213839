import React, { useState } from "react";
import styled from "styled-components";
import { BoldParagraph2, Heading5 } from "../../theme/typography";
import TradeFaqs from "../FaqsPageComponent/TradeFaqs";
import CarbonCreditFaqs from "../FaqsPageComponent/CarbonCreditFaqs";
import DepositFaqs from "../FaqsPageComponent/DepositFaqs";
import TermsAndConditionFaqs from "../FaqsPageComponent/TermsAndConditionFaqs";
import WithdrawFaqs from "../FaqsPageComponent/WithdrawFaqs";
import { useMode } from "../../store/mode/hooks";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  width: 78%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const CategoryBox = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    padding-bottom: 40px;
    gap: 20px;
  }
`;
const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
  @media (max-width: 710px) {
    width: 80%;
    justify-content: start;
    gap: 16px;
  }
`;
const ContentBox = styled.div`
  width: 75%;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`;
const Index = () => {
  const mode = useMode();
  const [selected, setSelected] = useState("trade");
  return (
    <MainDiv>
      <CategoryBox>
        <Heading5 mode={mode}>Categories</Heading5>
        <Categories>
          <BoldParagraph2
            mode={mode}
            onClick={() => setSelected("trade")}
            style={{
              color: `${selected === "trade" ? "#03C988" : ""}`,
              fontSize: `${selected === "trade" ? "20px" : ""}`,
            }}
          >
            Trade
          </BoldParagraph2>
          <BoldParagraph2
            mode={mode}
            onClick={() => setSelected("Deposit")}
            style={{
              color: `${selected === "Deposit" ? "#03C988" : ""}`,
              fontSize: `${selected === "Deposit" ? "20px" : ""}`,
            }}
          >
            Deposit
          </BoldParagraph2>
          <BoldParagraph2
            mode={mode}
            onClick={() => setSelected("withdraw")}
            style={{
              color: `${selected === "withdraw" ? "#03C988" : ""}`,
              fontSize: `${selected === "withdraw" ? "20px" : ""}`,
            }}
          >
            Withdraw
          </BoldParagraph2>
          <BoldParagraph2
            mode={mode}
            onClick={() => setSelected("termsAndCondition")}
            style={{
              color: `${selected === "termsAndCondition" ? "#03C988" : ""}`,
              fontSize: `${selected === "termsAndCondition" ? "20px" : ""}`,
            }}
          >
            Terms and Condition
          </BoldParagraph2>
          <BoldParagraph2
            mode={mode}
            onClick={() => setSelected("CarbonCredit")}
            style={{
              color: `${selected === "CarbonCredit" ? "#03C988" : ""}`,
              fontSize: `${selected === "CarbonCredit" ? "20px" : ""}`,
            }}
          >
            Carbon Credit
          </BoldParagraph2>
        </Categories>
      </CategoryBox>
      <ContentBox>
        {selected === "trade" && <TradeFaqs />}
        {selected === "Deposit" && <DepositFaqs />}
        {selected === "withdraw" && <WithdrawFaqs />}
        {selected === "termsAndCondition" && <TermsAndConditionFaqs />}
        {selected === "CarbonCredit" && <CarbonCreditFaqs />}
      </ContentBox>
    </MainDiv>
  );
};

export default Index;
