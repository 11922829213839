import React from "react";
import styled from "styled-components";
import { Heading6, Paragraph2 } from "../../theme/typography";
import SelfCustodyImage from "../../asset/mockups-ss/selfcustody.svg";
import SelfCustodyDarkImage from "../../asset/mockups-ss/selfcustody-dark.svg";
// import SelfCustodyImage from "../../asset/mockups-ss/selfcustody.webp";
// import SelfCustodyDarkImage from "../../asset/mockups-ss/selfcustody-dark.webp";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { motion } from "framer-motion";
import { BREAKPOINTS } from "../../theme/breakpoints";
import { isBrowser } from "react-device-detect";

const MainDiv = styled.div`
  width: 65%;
  /* border: 1px solid; */
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    flex-direction: column-reverse;
    gap: 8px;
    align-items: center;
  }
`;
const TextBox = styled.div`
  width: 40%;
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  text-align: right;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 80%;
    text-align: left;
  }
`;
const ImageBox = styled.div`
  width: 42%;
  /* border: 1px solid; */
  display: flex;
  /* justify-content: end; */
  position: relative;
  right: 6%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    right: 10px;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 400px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 330px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 370px;
  }
`;

const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;
const Index = () => {
  const mode = useMode();
  return (
    <MainDiv>
      <ImageBox>
        <Image
          as={motion.img}
          initial={{ translateX: "-300px", opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          viewport={{ once: true, amount: isBrowser? 0.5 : 0 }}
          transition={{
            ease: "easeInOut",
            delay: 0.4,
            duration: 1.5,
            type: "spring",
          }}
          src={mode === MODE.DARK ? SelfCustodyDarkImage : SelfCustodyImage}
          alt=""
        />
      </ImageBox>
      <TextBox
        as={motion.div}
        initial={{ translateX: "300px", opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 1 }}
        viewport={{ once: true, amount: isBrowser? 0.5 : 0 }}
        transition={{
          ease: "easeInOut",
          delay: 0.4,
          duration: 1.5,
          type: "spring",
        }}
      >
        {/* <Paragraph3>Lorium Ipsum</Paragraph3> */}
        <CustomHeading6 mode={mode}>True Self-Custody</CustomHeading6>
        <CustomParagraph2 mode={mode}>
          Maintain full control over your assets. With Buckmint, your funds stay
          in your decentralized (self-custody) wallet until you decide to
          trade. 
        </CustomParagraph2>
      </TextBox>
    </MainDiv>
  );
};

export default Index;
