import React from "react";
import styled from "styled-components";
import TextColor, { Heading5 } from "../../theme/typography";
import { TagColor } from "../../theme/colors";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column; 
  width: 78%;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const CategoriesLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 32px;
  @media (max-width: ${BREAKPOINTS.xxxl}px) {
    row-gap: 16px;
    column-gap: 26px;
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    row-gap: 16px;
    column-gap: 16px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    row-gap: 8px;
    column-gap: 8px;
  }
`;
const Tag = styled.div<{ selected: boolean; mode: MODE }>`
  display: flex;
  padding: 8px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) =>
    props.mode === MODE.DARK ? TagColor.secondary : TagColor.primary};
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.mode === MODE.DARK ? "" : TagColor.hovered};
  }
  &.crypto {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.carboncredit {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.decentralisation {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.finance {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.blockchain {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.web {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.decentralised-exchanges {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.environment {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.climate-change {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.sustainability {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.carbon-neutrality {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  &.environment {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
    color: ${(props) =>
      props.selected
        ? props.mode === MODE.DARK
          ? "rgba(0, 0, 0, 0.60)"
          : "#EEE"
        : ""};
  }
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.04);
  /* text */
  color: var(--Text-Secondary, rgba(0, 0, 0, 0.6));
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.paragraphWhite : TextColor.paragraph};
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    text-transform: none;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;
const CustomHeading5 = styled(Heading5)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
interface props{
  selectedList:string[];
  setSelectedList: React.Dispatch<React.SetStateAction<string[]>>
}

const Index = ({selectedList,setSelectedList}:props) => {
  const mode = useMode();
  const handleClick = (category: string) => {
    setSelectedList((previousList) =>
      previousList.includes(category)
        ? previousList.filter((item) => item !== category)
        : [...previousList, category],
    );
  };
  
  return (
    <MainDiv id="main">
      <CustomHeading5 mode={mode}>Categories</CustomHeading5>
      <CategoriesLine>
        <Tag
          mode={mode}
          className="crypto"
          onClick={() => handleClick("Cryptocurrency")}
          selected={selectedList.includes("Cryptocurrency")}
        >
          Cryptocurrency
        </Tag>
        <Tag
          mode={mode}
          className="carboncredit"
          onClick={() => handleClick("Carbon Credit")}
          selected={selectedList.includes("Carbon Credit")}
        >
          Carbon Credit
        </Tag>
        <Tag
          mode={mode}
          className="decentralisation"
          onClick={() => handleClick("Decentralization")}
          selected={selectedList.includes("Decentralization")}
        >
          Decentralization
        </Tag>
        <Tag
          mode={mode}
          className="finance"
          onClick={() => handleClick("Finance")}
          selected={selectedList.includes("Finance")}
        >
          Finance
        </Tag>
        <Tag
          mode={mode}
          className="blockchain"
          onClick={() => handleClick("Blockchain")}
          selected={selectedList.includes("Blockchain")}
        >
          Blockchain
        </Tag>
        <Tag
          mode={mode}
          className="web"
          onClick={() => handleClick("Web 3.0")}
          selected={selectedList.includes("Web 3.0")}
        >
          Web 3.0
        </Tag>
        <Tag
          mode={mode}
          className="decentralised-exchanges"
          onClick={() => handleClick("Decentralized Exchanges")}
          selected={selectedList.includes("Decentralized Exchanges")}
        >
          Decentralized Exchanges
        </Tag>
        {/* </CategoriesLine>
      <CategoriesLine> */}
        <Tag
          mode={mode}
          className="environment"
          onClick={() => handleClick("Environment")}
          selected={selectedList.includes("Environment")}
        >
          Environment
        </Tag>
        <Tag
          mode={mode}
          className="climate-change"
          onClick={() => handleClick("Climate Assets")}
          selected={selectedList.includes("Climate Assets")}
        >
          Climate Assets
        </Tag>
        <Tag
          mode={mode}
          className="sustainability"
          onClick={() => handleClick("Sustainability")}
          selected={selectedList.includes("Sustainability")}
        >
          Sustainability
        </Tag>
        <Tag
          mode={mode}
          className="carbon-neutrality"
          onClick={() => handleClick("Carbon Neutrality")}
          selected={selectedList.includes("Carbon Neutrality")}
        >
          Carbon Neutrality
        </Tag>
      </CategoriesLine>
    </MainDiv>
  );
};

export default Index;
