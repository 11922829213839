import React, { 
  // useEffect,
   useRef, useState } from 'react'
import styled from 'styled-components'
import { MODE } from '../../../store/mode'
import { useMode } from '../../../store/mode/hooks'
// import likesIconWhite from '../../../asset/pngs/likes-white.png';
// import likesIconBlack from '../../../asset/pngs/likes-black.png';
// import likedIcon from '../../../asset/pngs/liked-active-icon.png'
import shareIconWhite from '../../../asset/pngs/share-icon-white.png'
import shareIconBlack from '../../../asset/pngs/share-icon-black.png'
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { getBlogPostbyId } from '../../../utils/fetch';
import ShareCard from './ShareCard';


const MainDiv = styled.div<{mode:MODE}>`
    width: 80px;
    height: 40px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 24px;
    background-color: ${(props)=>props.mode===MODE.DARK?'#212121c3':'#fafafaf2'} ;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 12;
`
// const LikeContainer = styled.div`
//     display: flex;
//     position: relative;
// `
// const LikeIcon = styled.img`
//     height: 24px;
//     width: 24px;
//     cursor: pointer;
//     &:active{
//         transform: scale(0.8);
//     }
// `
const ShareIcon = styled.img`
    height: 24px;
    width: 24px;
    cursor: pointer;
    &:active{
        transform: scale(0.8);
    }
`
// const LikeCount= styled.div`
//     background-color: #0078d4;
//     height: 20px;
//     width: 20px;
//     border-radius: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #ffffff;
//     font-size: 10px;
//     font-family: "open sans";
//     position: absolute;
//     bottom: 10px;
//     left: 20px;
// `
const ShareCardBox= styled.div`
  position:absolute;
  bottom: 50px;
  /* left: 70px; */
`
interface props{
  image:any;
  title:any;
}
const Index = ({title,image}:props) => {
    const mode = useMode()
    // const {id} = useParams()
    // const [likeActive, setLikeActive] = useState<boolean>(false);
    // const [Likes,setLikes]= useState<any>()    
    const [shareCardActive , setShareCardActive] = useState(false)
    const shareCardRef = useRef<HTMLDivElement>(null)
    // useEffect(()=>{
    // const fetchBlogPost = async () => {
    //     try {
    //       const data = await getBlogPostbyId(id);
    //       setLikes(data?.data?.likes);
    //     } catch (error) {
    //       console.error('Failed to fetch blog post:', error);
    //     }
    //   };
    //   fetchBlogPost();
    // }, [id,likeActive]);
    
    // useEffect(() => {
    //     const savedLike = localStorage.getItem(`like-${id}`);
    //     if (savedLike) {
    //       setLikeActive(JSON.parse(savedLike));
    //     }
    //   }, [id]);
    
    // const handleClick = async ()=>{
    //     if(!likeActive){
    //         try {
    //             await axios({ 
    //              method: "post",
    //              url: `https://admin-api.buckmint.org/api/blog/like-post/${id}`,
    //              })
    //             }catch (error) {
    //                 console.log(error)
    //             }
    //         }else{
    //           // 
    //         }
    //         setLikeActive(!likeActive);
    //         localStorage.setItem(`like-${id}`, JSON.stringify(!likeActive));
    // }

    const closeCard = (e:any)=>{
      if(!shareCardRef?.current?.contains(e.target)){
        setShareCardActive(false)
      }
    }
    document.addEventListener("mousedown", closeCard)
  return (
    <MainDiv mode={mode}>
        {/* <LikeContainer>
        <LikeIcon onClick={handleClick} src={likeActive?likedIcon: mode===MODE.DARK?likesIconWhite:likesIconBlack} alt="" />
       {Likes>0 && <LikeCount>{Likes}</LikeCount>}
        </LikeContainer> */}
        <ShareIcon onClick={()=>!shareCardActive && setShareCardActive(!shareCardActive)} src={mode===MODE.DARK?shareIconWhite:shareIconBlack} alt="" />
          {
          shareCardActive &&
          <ShareCardBox ref={shareCardRef}>
              <ShareCard image={image} title={title} setShareCardActive={setShareCardActive}/>
          </ShareCardBox>
          }
    </MainDiv>
  )
}

export default Index