import React from "react";
import styled from "styled-components";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { componentColor } from "../../theme/colors";
import recievedIcon from "../../asset/pngs/recieved-icon.png";
import { Heading6, Paragraph1 } from "../../theme/typography";
import crossIconDark from "../../asset/svgs/cross-icon-dark.svg";
import crossIconLight from "../../asset/svgs/cross-icon-light.svg";
const MainDiv = styled.div<{ mode: MODE }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) =>
    props.mode === MODE.DARK
      ? componentColor.secondary
      : componentColor.primary};
  z-index: 99999;
  display: inline-flex;
  width: 400px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow:
    0px 4px 18px 3px rgba(0, 0, 0, 0.12),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 6px 6px -3px rgba(0, 0, 0, 0.2);
`;
const Icon = styled.img`
  width: 85px;
  height: 85px;
  margin-top: 40px;
`;
const CrossIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

interface props {
  setIsModelActive: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ setIsModelActive }: props) => {
  setTimeout(() => {
    setIsModelActive(false);
  }, 10000);
  const mode = useMode();
  return (
    <MainDiv mode={mode}>
      <CrossIcon
        onClick={() => setIsModelActive(false)}
        src={mode === MODE.DARK ? crossIconDark : crossIconLight}
        alt=""
      />
      <Heading6 mode={mode}>Thank you for reaching out to us</Heading6>
      <Paragraph1 mode={mode}>We will get back to you shortly</Paragraph1>
      <Icon src={recievedIcon} alt="" />
    </MainDiv>
  );
};

export default Index;
