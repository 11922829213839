import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Heading4, Paragraph1, Paragraph2 } from "../../theme/typography";
import HeroImg from "../../asset/webpack-images/hero.webp";
import HeroDarkImg from "../../asset/webpack-images/hero-dark.webp";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  width: 100%;
  padding-left: 11%;
  height: 100%;
  gap: 26px;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-left: 9%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-left: 6%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-left: 3%;
  }
`;
const SlideRightAnimation = keyframes`
from{ opacity:0; transform:translateX(-500px); }
80% {opacity:1; transform:translateX(50px);}
to {opacity:1; transform:translateX(0px);}
`
const SlideLeftAnimation = keyframes`
0%{ opacity:0; transform:translateX(500px);}
80% {opacity:1; transform:translateX(-50px);}
100% {opacity:1; transform:translateX(0px);}
`
const TextDiv = styled.div`
  /* border: 1px solid; */
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  animation: ${SlideRightAnimation};
  animation-duration: 1.5s;
  animation-timing-function:ease-in-out;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 100%;
    padding-right: 12%;
    align-items: center;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-right: 9%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-right: 6%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-right: 3%;
    text-align: center;
  }
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
`;
const Sustainability = styled.h4`
  color: var(--Primary-main, #03c988);
  font-family: Montserrat;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  margin-left: 6px;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    display: flex;
    gap: 7px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 28px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;

const LaunchAppButton = styled.div<{ mode: MODE }>`
  display: flex;
  padding: 12px 24px;
  width: 160px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => (props.mode === MODE.DARK ? "#03B77C" : "#56dbaf")};
  &:hover {
    background-color: #03c988;
    cursor: pointer;
  }
  /* Text */
  color: ${(props) => (props.mode === MODE.DARK ? "#212121" : "#fafafa")};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
    padding: 10px 26px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 13px;
    padding: 8px 20px;
  }
`;
const HeroImageDiv = styled.div`
  width: calc(70% - 26px);
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-left: 3%;
    width: 110%;
  }
`;
const HeroImage = styled.img`
  width: 137%;
  /* height: 100%; */
  z-index: 99;
  flex-shrink: 0;
  animation: ${SlideLeftAnimation};
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;

`;
const CustomHeading4 = styled(Heading4)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    display: flex;
    gap: 7px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 28px;
    font-weight: 500;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;
const CustomParagraph1 = styled(Paragraph1)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 15px;
    font-weight: 500;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (min-width: ${BREAKPOINTS.lg}px) {
    text-align: justify;
    padding-right: 45px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;
const Hero = () => {
  const mode = useMode();
  const HeroImgRef = useRef<HTMLImageElement | null>(null);
  return (
    <MainDiv>
      <TextDiv>
        <CustomHeading4 mode={mode}>
          <Heading>
            {" "}
            Trade <Sustainability> Sustainability </Sustainability>,
          </Heading>{" "}
          Not just Tokens{" "}
        </CustomHeading4>
        <CustomParagraph1 mode={mode}>
          Decentralized Exchange for Climate Assets
        </CustomParagraph1>
        <CustomParagraph2 mode={mode}>
          A decentralized exchange (DEX) designed for the future of sustainable
          trading. We specialize in Climate assets like carbon credits, offering
          a platform that combines sustainability, self-custody and gasless
          trading.
        </CustomParagraph2>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe3zLJnIVcGLagJcfXHEEJTVWpCk9KcYI0o0Jhj5Ldk4vOWZw/viewform?"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LaunchAppButton mode={mode}>Join Waitlist</LaunchAppButton>
        </a>
          
      </TextDiv>
      <HeroImageDiv>
        <HeroImage
          ref={HeroImgRef}
          src={mode === MODE.DARK ? HeroDarkImg : HeroImg}
          alt=""
        />
        
      </HeroImageDiv>
    </MainDiv>
  );
};

export default Hero;
