import React from "react";
import styled from "styled-components";
import storyImage from "../../asset/images/story-image-min.webp";
import  { Heading4, Paragraph2 } from "../../theme/typography";
import storyIcon from "../../asset/svgs/story-Icon.svg";
import storyWhiteIcon from "../../asset/svgs/story-icon-white.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  width: 78%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
    flex-direction: column-reverse;
    gap: 20px;
    align-items: center;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const TextBox = styled.div`
  width: calc(50% - 50px);
  display: flex;
  gap: 35px;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    width: calc(60% - 30px);
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: calc(60% - 15px);
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    gap: 20px;
    width: 95%;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;
const ImageBox = styled.div`
  width: 50%;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    width: 40%;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const StoryImage = styled.img`
  width: 100%;
  /* height: 400px; */
  border-radius: 8px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 60%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 426px;
    height: 288px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
    height: auto;
  }
`;
const RotatedHeading = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding-left: 0;
  }
`;
const StoryIcon = styled.img`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 220px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 187px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 157px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 111px;
  }
`;
const MainText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;
const DescriptionBox = styled.div`
  width: 95%;
`;
const CustomHeading4 = styled(Heading4)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 28px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;
const Index = () => {
  const mode = useMode();
  return (
    <MainDiv>
      <TextBox>
        <RotatedHeading>
          <StoryIcon
            src={mode === MODE.DARK ? storyWhiteIcon : storyIcon}
            alt=""
          />
        </RotatedHeading>
        <MainText>
          <CustomHeading4 mode={mode}>
            Building a Sustainable Future with Buckmint
          </CustomHeading4>
          <DescriptionBox>
            <CustomParagraph2 mode={mode}>
              At Buckmint, our journey began with a simple idea: to harness the
              power of technology for positive change in the world. Founded by a
              team of passionate innovators, we set out to create a platform
              that would empower individuals and businesses to make sustainable
              choices effortlessly. Inspired by the resilience of nature and the
              spirit of collaboration, Buckmint is dedicated to building a
              brighter future for generations to come.
            </CustomParagraph2>
          </DescriptionBox>
        </MainText>
      </TextBox>
      <ImageBox>
        <StoryImage loading="lazy" src={storyImage} alt="" />
      </ImageBox>
    </MainDiv>
  );
};

export default Index;
