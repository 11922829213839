import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextColor, { Heading6, Paragraph2 } from "../../../theme/typography";
import blackLogo from "../../../asset/svgs/buckmint-black-logo.svg";
import whiteLogo from "../../../asset/svgs/buckmint-white-logo.svg";
import dot from "../../../asset/svgs/ellipse-dot.svg";
import whiteDot from "../../../asset/svgs/white-dot.svg";
import { useMode } from "../../../store/mode/hooks";
import { MODE } from "../../../store/mode";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BREAKPOINTS } from "../../../theme/breakpoints";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { scroller } from "react-scroll";
import { blogs } from "../../../constant/blogPosts";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const MainDiv = styled.div`
  width: 78%;
  display: grid;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 60px;
  @media (max-width: ${BREAKPOINTS.xxxl}px) {
    row-gap: 30px;
    column-gap: 40px;
  }
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    column-gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    /*  */
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    /*  */
  }
`;
const SingleBlogCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* width: 30%; */
  position: relative;
`;
const SingleBlogImage = styled.img`
  width: 100%;
  height: 350px;
  border-radius: 8px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 320px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 220px;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const PublisherDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`;
const CustomPublisherDiv = styled.div`
  display: none;
  gap: 4px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
  }
`;
const PublisherTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    
    flex-direction: row;
    gap: 4px;
  }
`;
const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  width: 56px;
  height: 56px;
  fill: var(--layers-layer-150900, #fafafa);
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.04));
`;
const LogoIcon = styled.img`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 56px;
    height: 56px;
  }
`;
const TagBox = styled.div`
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  position: absolute;
  top: 12px;
  left: 12px;
`;
const Tag = styled.div<{ mode: MODE }>`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => (props.mode === MODE.DARK ? "#424242" : "#e0e0e0")};
  /* Text */
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.secondary : TextColor.primary};
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
`;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 157%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    font-weight: 500;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 17px;
    font-weight: 500;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
text-align: justify;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;

const PaginationDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
const CustomPagination = styled(Pagination)<{ mode: MODE }>`
  & .MuiPaginationItem-root {
    color: ${(props) =>
      props.mode === MODE.DARK ? "#FFFFFF" : "rgba(0,0,0,0.60)"};
    border-color: ${(props) =>
      props.mode === MODE.DARK ? "rgba(255,255,255,0.60)" : "rgba(0,0,0,0.60)"};
  }
  & .MuiPaginationItem-outlined {
    background-color: ${(props) =>
      props.mode === MODE.DARK ? "#FFFFFF20" : ""};
    &:hover {
      background-color: ${(props) =>
        props.mode === MODE.DARK ? "#ffffff22" : "#00000020"};
    }
  }
`;

interface props {
  selectedList: string[];
  search: string;
}

const Index = ({ selectedList, search }: props) => {
  const mode = useMode();
  const [blogData, setBlogData] = useState<any[]>([]);
  const [currentPageData, setCurrentPageData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  // const blogs = useBlogData();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const { page: pageParam } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (pageParam) {
      setPage(Number(pageParam));
    }
  }, [pageParam]);


  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    navigate(`/blog/page/${value}`);
    scroller.scrollTo("main", {
      duration: 800,
      delay: 0,
      offset: -120,
      // smooth: "easeinout",
    });
  };

  useEffect(() => {
    const sortedBlogs = blogs?.sort((a: any, b: any) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    setBlogData(sortedBlogs);
    setTotalPages(Math.ceil(blogs?.length / 6));
    setCurrentPageData(blogs?.slice(0, 6));
  }, []);

  useEffect(() => {
    const startIndex = (page - 1) * 6;
    const endIndex = page * 6;
    setCurrentPageData(blogData?.slice(startIndex, endIndex));
  }, [page, blogData]);

  useEffect(() => {
    const filtered =
      selectedList?.length === 0
        ? blogData
        : blogData?.filter((data) =>
            data.category?.some((category: string) =>
              selectedList.includes(category)
            )
          );
    setTotalPages(Math.ceil(filtered?.length / 6));
    setCurrentPageData(filtered?.slice(0, 6));
    setPage(1);
  }, [selectedList, blogData]);

  useEffect(() => {
    if (search) {
      const filtered = blogData?.filter((data) =>
        data.title.toLowerCase().includes(search.toLowerCase())
      );
      setTotalPages(Math.ceil(filtered?.length / 6));
      setCurrentPageData(filtered?.slice(0, 6));
      setPage(1);
    } else {
      setCurrentPageData(blogData?.slice(0, 6));
      setTotalPages(Math.ceil(blogData?.length / 6));
    }
  }, [search, blogData]);

  const cleanHtmlContent = (htmlContent:any) => {
    if (!htmlContent) return "";
    return htmlContent.replace(/<br\s*\/?>/gi, "");
  };
  return (
    <MainContainer>
      <MainDiv>
        {currentPageData?.map((data, index) => (
            <Link
              // target="blank"
              to={`/detail/${data?.permalink}`}
              onClick={() => window.scrollTo(0, 0)}
            >
          <SingleBlogCard key={data?.id}>
              {(
                <SingleBlogImage
                  loading="lazy" 
                  key={data?.id}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  src={data?.imageUrl}
                  alt=""
                />
              )}
            
            <TagBox>
              {data?.category?.map((data: any, categoryIndex: number) =>
                 (
                  <Tag mode={mode} key={categoryIndex}>
                    {data}
                  </Tag>
                )
              )}
            </TagBox>
            <TextDiv>
              <CustomHeading6 mode={mode}>
                { hoveredIndex !== index ? (
                  data?.title?.length > 50 ? (
                    data?.title?.substr(0, 50) + " ...."
                  ) : (
                    data?.title
                  )
                ) : (
                  data?.title
                )}
              </CustomHeading6>
              <CustomParagraph2 mode={mode} dangerouslySetInnerHTML={{__html:  cleanHtmlContent(data?.content).substr(0, 295) + " ...."}}/>
                
            </TextDiv>
            { (
              <PublisherDiv>
                <LogoDiv>
                  <LogoIcon
                    style={{ width: "33px", height: "33.104px" }}
                    src={mode === MODE.DARK ? whiteLogo : blackLogo}
                    alt=""
                  />
                </LogoDiv>
                <CustomParagraph2 mode={mode}>Buckmint Team</CustomParagraph2>
                <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
                <CustomParagraph2 mode={mode}>
                  {moment(data?.createdAt).format("MMMM Do YYYY")}
                </CustomParagraph2>
              </PublisherDiv>
            )}
            <CustomPublisherDiv>
              <LogoDiv>
                <LogoIcon
                  style={{ width: "33px", height: "33.104px" }}
                  src={mode === MODE.DARK ? whiteLogo : blackLogo}
                  alt=""
                />
              </LogoDiv>
              <PublisherTextBox>
                <CustomParagraph2 mode={mode}>Buckmint Team</CustomParagraph2>
                <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
                <CustomParagraph2 mode={mode}>
                  {moment(data?.createdAt).format("MMMM Do YYYY")}
                </CustomParagraph2>
              </PublisherTextBox>
            </CustomPublisherDiv>
          </SingleBlogCard>
          </Link>
        ))}
      </MainDiv>

      <PaginationDiv>
        <Stack spacing={2}>
          <CustomPagination
            mode={mode}
            count={totalPages}
            page={ pageParam ? Number(pageParam):page}  
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </Stack>
      </PaginationDiv>
    </MainContainer>
  );
};

export default Index;
