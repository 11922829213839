import React from "react";
import styled from "styled-components";
import searchIcon from "../../asset/svgs/search-icon.svg";
import searchLightIcon from "../../asset/svgs/search-light-icon.svg";
import TextColor from "../../theme/typography";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 78%;
  justify-content: start;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const SearchBox = styled.div<{ mode: MODE }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid
    ${(props) =>
      props.mode === MODE.DARK
        ? "rgba(255, 255, 255, 0.40)"
        : "rgba(0, 0, 0, 0.40)"};
`;
const CustomInput = styled.input<{ mode: MODE }>`
  outline: none;
  width: 330px;
  background: none;
  border: none;
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.paragraphWhite : TextColor.paragraph};
  font-family: "Open Sans";
  font-size: 14px;
  padding-bottom: 4px;
  @media (max-width: ${BREAKPOINTS.sm}px){
    width: 250px;
  }
`;
interface props {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}
const BlogSearchBox = ({ setSearch }: props) => {
  const mode = useMode();

  return (
    <MainDiv>
      <img src={mode === MODE.DARK ? searchIcon : searchLightIcon} alt="" />
      <SearchBox mode={mode}>
        <CustomInput
          mode={mode}
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search......."
        />
      </SearchBox>
    </MainDiv>
  );
};

export default BlogSearchBox;
