import React from "react";
import styled from "styled-components";
import { Heading6, Paragraph2 } from "../../theme/typography";
import ClimateImage from "../../asset/mockups-ss/sustainable.svg";
import ClimateDarkImage from "../../asset/mockups-ss/sustainable-dark.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { motion } from "framer-motion";
import { BREAKPOINTS } from "../../theme/breakpoints";
import { isBrowser } from "react-device-detect";

const MainDiv = styled.div`
  width: 65%;
  /* border: 1px solid; */
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`;
const TextBox = styled.div`
  width: 40%;
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    text-align: left;
    width: 80%;
  }
`;
const ImageBox = styled.div`
  width: 40%;
  /* border: 1px solid; */
  display: flex;
  justify-content: end;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    justify-content: center;
  }
`;
const ClimateImg = styled.img`
  width: 375px;
  /* height: 307px; */
  position: relative;
  left: 20%;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 300px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    left: 10px;
    width: 375px;
  }
`;

const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 157%; /* 21.98px */
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;
const Index = () => {
  const mode = useMode();
  console.log(isBrowser)
  return (
    <MainDiv>
      <TextBox
        as={motion.div}
        initial={{ translateX: "-300px", opacity: 0 }}
        whileInView={{ translateX: 0, opacity: 1 }}
        viewport={{ once: true, amount: isBrowser? 0.5 : 0 }}
        transition={{
          ease: "easeInOut",
          delay: 0.4,
          duration: 1.5,
          type: "spring",
        }}
      >
        {/* <Paragraph3>Lorium Ipsum</Paragraph3> */}
        <CustomHeading6 mode={mode}>Climate-Focused Trading</CustomHeading6>
        <CustomParagraph2 mode={mode}>
          Be a part of the solution to climate change. Trade climate assets and
          contribute to a sustainable future.
        </CustomParagraph2>
      </TextBox>
      <ImageBox>
        <ClimateImg
          as={motion.img}
          initial={{ translateX: "300px", opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          viewport={{ once: true, amount: isBrowser? 0.5 : 0 }}
          transition={{
            ease: "easeInOut",
            delay: 0.4,
            duration: 1.5,
            type: "spring",
          }}
          src={mode === MODE.DARK ? ClimateDarkImage : ClimateImage}
          alt=""
        />
      </ImageBox>
    </MainDiv>
  );
};

export default Index;
