import React, { useState } from "react";
import styled from "styled-components";
import logo from "../../../asset/header-icons/logo.svg";
import logoName from "../../../asset/header-icons/logo-name.svg";
import { componentColor } from "../../../theme/colors";
import { BoldParagraph2 } from "../../../theme/typography";
import LaunchButton from "../../Buttons/LaunchButton";
import DarkModeToggle from "../../Buttons/ModeToggle";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMode } from "../../../store/mode/hooks";
import { MODE } from "../../../store/mode";
import { BREAKPOINTS } from "../../../theme/breakpoints";
import openMenuLight from "../../../asset/header-icons/open-menu-light.svg";
import openMenuDark from "../../../asset/header-icons/open-menu-dark.svg";
import closeMenuLight from "../../../asset/header-icons/close-menu-light.svg";
import closeMenuDark from "../../../asset/header-icons/close-menu-dark.svg";
import Sidebar from "./Sidebar";
import { Slide } from "@mui/material";
const MainDiv = styled.div<{ mode: MODE }>`
  position: fixed;
  z-index: 99999;
  display: flex;
  width: 78%;
  padding: 8px 12px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: ${(props) =>
    props.mode === MODE.DARK
      ? componentColor.secondary
      : componentColor.primary};
  box-shadow: 0px 4px 9.8px 0px rgba(0, 0, 0, 0.07);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const OptionDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 12px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`;
const Option = styled.div<{ optionSelected: string }>`
  display: flex;
  padding: 10px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &.AboutUs {
    border-bottom: ${(props) =>
      props.optionSelected === "AboutUs" && " 1px solid #03C988"};
  }
  &.Blogs {
    border-bottom: ${(props) =>
      props.optionSelected === "Blogs" && " 1px solid #03C988"};
  }
  &.mobile-toggle {
    @media (max-width: ${BREAKPOINTS.sm}px) {
      position: relative;
      top: 1px;
    }
  }
`;
const LogoImg = styled.img`
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 30px;
    height: 30px;
  }
`;
const LogoNameImg = styled.img`
  width: 109px;
`;
const DropDownMenu = styled.div`
  @media (min-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
  display: flex;
  gap: 20px;
  align-items: center;
`;
const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-bottom: 2px;
`;
const SideBarMenu = styled.div`
  width: 70vw;
  height: 100vh;
  position: absolute;
  top: -10px;
  left: -15px;
  z-index: 999;
`;

const Navbar = () => {
  const mode = useMode();
  const location = useLocation();
  const [optionSelected, setOptionSelected] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { page: pageParam } = useParams();
  const { permalink } = useParams();

  React.useEffect(() => {
    if (location.pathname === "/about") {
      setOptionSelected("AboutUs");
    } else if (location.pathname === `/blog/page/${pageParam}` || (location.pathname === `/detail/${permalink}`)) {
      setOptionSelected("Blogs");
    } else {
      setOptionSelected("Home");
    }
  }, [location.pathname,pageParam, permalink]);
  return (
    <MainDiv mode={mode}>
      <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
        <LogoDiv>
          <LogoImg src={logo} alt="" />
          <LogoNameImg src={logoName} alt="" />
        </LogoDiv>
      </Link>
      <OptionDiv>
        <Link
          style={{ textDecoration: "none" }}
          to={"/about"}
          onClick={() => window.scrollTo(0, 0)}
        >
          <Option className="AboutUs" optionSelected={optionSelected}>
            <BoldParagraph2
              style={{ color: `${optionSelected === "AboutUs" && "#03C988"}` }}
              mode={mode}
            >
              About Us
            </BoldParagraph2>
          </Option>
        </Link>
        <Link style={{textDecoration:"none"}} to={"/blog/page/1"} onClick={ ()=>window.scrollTo(0,0)}>
        <Option className="Blogs" optionSelected={optionSelected}>
          <BoldParagraph2 style={{color:`${optionSelected==="Blogs" && "#03C988"}`}} mode={mode}>Blogs</BoldParagraph2>
        </Option>
        </Link>
        <Option optionSelected={optionSelected}>
          <DarkModeToggle />
        </Option>
        <Option optionSelected={optionSelected}>
          <LaunchButton />
        </Option>
      </OptionDiv>
      <DropDownMenu>
        <Option className="mobile-toggle" optionSelected={optionSelected}>
          <DarkModeToggle />
        </Option>
        {isMenuOpen ? (
          <MenuIcon
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            src={mode === MODE.DARK ? openMenuDark : openMenuLight}
            alt=""
          />
        ) : (
          <MenuIcon
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            src={mode === MODE.DARK ? closeMenuDark : closeMenuLight}
            alt=""
          />
        )}
      </DropDownMenu>
      <Slide direction="right" in={isMenuOpen} mountOnEnter unmountOnExit>
        <SideBarMenu>
          <Sidebar setIsMenuOpen={setIsMenuOpen} />
        </SideBarMenu>
     </Slide>
    </MainDiv>
  );
};

export default Navbar;
