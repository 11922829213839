import axios from "axios";
import { EXPERIENCE } from "../constant";
// import toast from "react-hot-toast";
import { MODE } from "../store/mode";
// import { componentColor } from "../theme/colors";
// import TextColor from "../theme/typography";

export const submitFeedbackCall = async (
  depositFeedack: EXPERIENCE,
  depositFeedbackText: string,
  withdrawFeedack: EXPERIENCE,
  withdrawFeedbackText: string,
  tradeFeedack: EXPERIENCE,
  tradeFeedbackText: string,
  overAllExperienceTitle: string,
  overAllExperienceText: string,
  helpUsImproveTitle: string,
  helpUsImproveText: string,
  email: string,
  subscribe: boolean,
): Promise<{ status: boolean; message: string }> => {
  try {
    await axios({
      method: "post",
      url: "https://exchange-testnet-api.buckmint.org/api/feedback/submit-detailed-feedback",
      data: {
        depositFeedack,
        depositFeedbackText,
        withdrawFeedack,
        withdrawFeedbackText,
        tradeFeedack,
        tradeFeedbackText,
        overAllExperienceTitle,
        overAllExperienceText,
        helpUsImproveTitle,
        helpUsImproveText,
        email,
        subscribe,
      },
    });

    return { status: true, message: "Feedback Submitted" };
  } catch (error) {
    if (error instanceof Error) {
      console.log("submitFeedbackCall failed", error?.message);
      return { status: false, message: error?.message };
    }
    return { status: false, message: "Feedback could not submitted" };
  }
};

// export const errorToast = (title: string, mode: MODE) => {
//   return toast.error(title, {
//     style: {
//       padding: "10px",
//       marginTop: "100px",
//       position: "relative",
//       zIndex: "999999",
//       backgroundColor:
//         mode === MODE.DARK ? componentColor.secondary : componentColor.primary,
//       color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
//     },

//     duration: 5000,
//     position: "top-right",
//   });
// };

// export const successToast = (title: string, mode: MODE) => {
//   return toast.success(title, {
//     style: {
//       padding: "10px",
//       marginTop: "100px",
//       position: "relative",
//       zIndex: "999999",
//       backgroundColor:
//         mode === MODE.DARK ? componentColor.secondary : componentColor.primary,
//       color: mode === MODE.DARK ? TextColor.secondary : TextColor.primary,
//     },

//     duration: 5000,
//     position: "top-right",
//   });
// };
export function getTheme(): MODE {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme:dark)").matches
  ) {
    return MODE.DARK;
  } else {
    return MODE.LIGHT;
  }
}
