import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMode } from "../../store/mode/hooks";
import TextColor, { Heading6, Paragraph2 } from "../../theme/typography";
import blackLogo from "../../asset/svgs/buckmint-black-logo.svg";
import whiteLogo from "../../asset/svgs/buckmint-white-logo.svg";
import dot from "../../asset/svgs/ellipse-dot.svg";
import whiteDot from "../../asset/svgs/white-dot.svg";
import { MODE } from "../../store/mode";
import { BREAKPOINTS } from "../../theme/breakpoints";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import {blogs} from '../../constant/blogPosts'

const MainDiv = styled.div`
  width: 78%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  top: -20px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const ContentBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;

`;

const SingleBlogCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  /* width: 35%; */
  position: relative;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 50%;
    
  }
`;
const SingleBlogImage = styled.img`
  width: 100%;
  height: 350px;
  border-radius: 8px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 300px;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const PublisherDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`;
const CustomPublisherDiv = styled.div`
  display: none;
  gap: 4px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
  }
`;
const PublisherTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  width: 56px;
  height: 56px;
  fill: var(--layers-layer-150900, #fafafa);
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.04));
`;
const LogoIcon = styled.img`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 56px;
    height: 56px;
  }
`;
const TagBox = styled.div`
  display: flex;
  gap: 13px;
  position: absolute;
  top: 12px;
  left: 12px;
  flex-wrap: wrap;
  padding-right: 20px;
`;
const Tag = styled.div<{ mode: MODE }>`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => (props.mode === MODE.DARK ? "#424242" : "#e0e0e0")};
  /* Text */
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.secondary : TextColor.primary};
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
`;
const MainHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    font-weight: 500;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 14px;
    font-weight: 500;
  }
`;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 157%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 14px;
    font-weight: 500;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 13px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;

const Index = () => {
  const mode = useMode();
  const [blogData, setBlogData] = useState<any[]>([]);
  const { permalink } = useParams<{ permalink?: string }>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [relatedBlogs, setRelatedBlogs] = useState<any[]>([]);
  
  useEffect(() => {
    setBlogData(blogs);
  }, [permalink]);

  useEffect(() => {
    if (blogData?.length > 0 && permalink) {
      const mainBlog = blogData.find((blog) => blog.permalink === (permalink));
      if (mainBlog) {
        setSelectedCategories(mainBlog?.category);
        console.log("-->",mainBlog?.category)
      }
    }
  }, [blogData, permalink]);

  useEffect(() => {
    if (selectedCategories?.length > 0) {
      const filtered = blogData?.filter((data) =>
        data?.category?.some((category: string) =>
          selectedCategories.includes(category)
        )
      );
      const Data=filtered.slice(0, 2)
      setRelatedBlogs(Data.filter((blog)=>String(blog?.permalink)!==permalink))
    }
  }, [selectedCategories, blogData, permalink]);

  console.log("relatedBlogs",relatedBlogs)

  return (
    <MainDiv>
      {relatedBlogs.length>0 &&
        <MainHeading6 mode={mode}>Related Blogs</MainHeading6>
      }
      <ContentBox>
        {relatedBlogs?.map((data, index) => (
          <SingleBlogCard key={index}>
            <Link
              to={`/detail/${data?.permalink}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <SingleBlogImage src={data?.imageUrl} alt="" />
            </Link>
            <TagBox>
              {data?.category?.map((data: any) => (
                <Tag mode={mode}>{data}</Tag>
              ))}
            </TagBox>
            <TextDiv>
              <CustomHeading6 mode={mode}>{data?.title}</CustomHeading6>
            </TextDiv>
            <PublisherDiv>
              <LogoDiv>
                <img
                  style={{ width: "33px", height: "33.104px" }}
                  src={mode === MODE.DARK ? whiteLogo : blackLogo}
                  alt=""
                />
              </LogoDiv>
              <Paragraph2 mode={mode}>Buckmint Team</Paragraph2>
              <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
              <Paragraph2 mode={mode}>
                {moment(data?.createdAt).format("MMMM Do YYYY")}
              </Paragraph2>
            </PublisherDiv>
            <CustomPublisherDiv>
              <LogoDiv>
                <LogoIcon
                  style={{ width: "33px", height: "33.104px" }}
                  src={mode === MODE.DARK ? whiteLogo : blackLogo}
                  alt=""
                />
              </LogoDiv>
              <PublisherTextBox>
                <CustomParagraph2 mode={mode}>Buckmint Team</CustomParagraph2>
                <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
                <CustomParagraph2 mode={mode}>
                  {moment(data?.createdAt).format("MMMM Do YYYY")}
                </CustomParagraph2>
              </PublisherTextBox>
            </CustomPublisherDiv>
          </SingleBlogCard>
        ))}
      </ContentBox>
    </MainDiv>
  );
};

export default Index;
